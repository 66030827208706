export default function clearFields() {
    console.log("clear fields");
    this.amount.take = 0;
    this.amount.give = 0;

    this.fields.take = this.fields.take.map(field => Object.assign({}, field, { value: "" }));
    this.fields.give = this.fields.give.map(field => Object.assign({}, field, { value: "" }));

    this.mobileNumber = "";
}
