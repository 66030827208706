import axios from "axios";

class UserService {
    resendVerify() {
        return axios.post("/profile/resend-verify");
    }
}

const userService = new UserService();

export default userService;
