<template>
    <div v-if="content" class="regulations">
        <div class="card">
            <div class="content" v-html="content"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(["notification"]),
        content() {
            let locale = this.$i18n.locale;

            let result = locale == "ru" ? this.notification.rus : this.notification.eng;

            return result;
            // === "ru" ? "en" : "ru";
        }
    },
    watch: {}
};
</script>

<style lang="scss" scoped>
.regulations {
    color: #656565;
    font-size: 16px;
    width: 100%;
}

.regulations .card {
    background-color: rgba(0, 0, 0, 0.16);
    padding: 16px 24px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.regulations .card .page-name {
    color: $grey-text;
    font-size: 28px;
    margin-bottom: 24px;
}

.regulations .card .content {
    line-height: 24px;
    color: black;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    max-width: 1100px;
}

@media (max-width: 739px) {
    .regulations .card {
        margin: 8px;
        padding: 24px 16px;
    }
}
</style>
