<template lang="">
    <div v-if="order" class="orderPage grid">
        <div class="orderPageCard cell cell--9-col cell--12-col-tablet cell--12-col-phone">
            <Header :sendExpireDate="order.sendExpireDate" :statusConfirmReceive="order.status == 104" :statusPayment="order.status == 102 || order.status == 101" :finished="order.finished" :created="order.created" :id="order._id" :hash="order.hash" />
            <ViewStepper :setOrder="setOrder" :orderStatus="orderStatus" :order="order" />
            <div class="orderPageDivider" />
            <div class="grid">
                <div class="cell cell--6-col cell--12-col-tablet cell--12-col-phone">
                    <ClientField direct="left" title="Ваши реквизиты Отдаю" :amount="getFieldsInfo.take.amount" :currencyName="getFieldsInfo.take.name" :currencyImage="getFieldsInfo.take.image" :fields="getFieldsInfo.take.fields" />
                </div>
                <div class="cell cell--6-col cell--12-col-tablet cell--12-col-phone">
                    <ClientField direct="right" title="Ваши реквизиты Получаю" :amount="getFieldsInfo.give.amount" :currencyName="getFieldsInfo.give.name" :currencyImage="getFieldsInfo.give.image" :fields="getFieldsInfo.give.fields" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// Services
import OrderService from "../../services/OrderService";

// Components
import ClientField from "./ClientField.vue";
import Header from "./Header.vue";
import ViewStepper from "./ViewStepper.vue";

export default {
    data() {
        return {
            order: null,
            now: new Date()
        };
    },
    components: {
        ClientField,
        Header,
        ViewStepper
    },

    computed: {
        currentHash() {
            let hash = this.$route.params?.hash;
            return hash;
        },
        getFieldsInfo() {
            return {
                take: {
                    name: this?.order?.currency?.take?.name,
                    image: this?.order?.currency?.take?.image,
                    fields: this?.order?.fields?.take,
                    amount: this?.order?.amount?.take
                },
                give: {
                    name: this?.order?.currency?.give?.name,
                    image: this?.order?.currency?.give?.image,
                    fields: this?.order?.fields?.give,
                    amount: this?.order?.amount?.give
                }
            };
        },
        orderStatus() {
            let order = this.order;
            if (!order) return;

            let status = order.status;

            if (status == 101 && order.card && order.card.status === 101) {
                return "card-process";
            } else if (order.card && order.card.status == 103) {
                return "card-reverify";
            } else if (status == 106 && order.card && order.card.status == 103) {
                let diff = new Date(this.order.finished) - this.now;
                if (diff <= 0) {
                    return "order-reject";
                } else {
                    return "card-reverify";
                }
            } else if (status == 106 && order.card && order.card.status == 104) {
                return "card-banned";
            } else if (order.direction && order.direction.offer) {
                return "order-direction";
            } else if (status == 102) {
                return "order-payment";
            } else if (status == 103) {
                return "order-process";
            } else if (status == 104) {
                return "order-send-process";
            } else if (status == 106) {
                return "order-reject";
            } else if (status == 105) {
                return "order-resolve";
            } else {
                return "";
            }
        }
    },
    watch: {
        currentHash() {
            let hash = this.currentHash;

            if (!hash) {
                this.clear();
            } else {
                this.setData();
            }
        }
    },
    methods: {
        setOrder(o) {
            this.order = o;
        },
        clear() {
            let hash = this?.order?.hash;
            if (!hash) return;

            let id = this?.order?._id;

            this.$socket.emit("auth_order_leave", hash);
            this.sockets.unsubscribe(`hash_update_order_${id}`);
            this.order = null;
        },
        async setData() {
            try {
                let hash = this.currentHash;
                this.$socket.emit("auth_order", hash);
                let { data: order } = await OrderService.getOneByHash(hash);
                this.order = order;
                this.sockets.subscribe(`hash_update_order_${order._id}`, o => {
                    this.order = o;
                });
            } catch (e) {
                console.error("Error set order data:", e);
            }
        }
    },
    created
};

function created() {
    this.timer = setInterval(() => {
        this.now = new Date();
    }, 1000);
    console.log("created");
    this.setData();
}
</script>
<style lang="scss" scoped>
.orderPage {
    color: #656565;
    font-size: 16px;
    justify-content: center;
}

.orderPageDivider {
    background-color: #07070721;
    height: 1px;
}

.orderPageCard {
    background-color: $white;
    margin: 16px;
    // padding: 24px 32px;
    border-radius: $border-sm;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}
</style>
