export default function(fields) {
    this.fieldsStamp = {
        exchange: this.exchange._id,
        fields: this.fields.take
    };

    if (!fields) return;
    fields.forEach(field => {
        this.validationTakeFields[field.id] = {
            isValid: false,
            text: field.text
        };
        this.validationGiveFields[field.id] = {
            isValid: false,
            text: field.text
        };
    });
}
