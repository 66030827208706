import axios from "axios";

async function checkPromocode() {
    try {
        if (!this.promocode) {
            this.promocodeInfo = null;
            return;
        }

        if (!this.isAuthenticated && !this.validateEmail(this.email)) {
            this.validations.email.isValid = false;
            this.validations.email.text = `Введите Email`;
            return;
        }

        let { data } = await axios.get("/promocodes/check-for-order", {
            params: {
                code: this.promocode,
                email: this.isAuthenticated ? this.user.email : this.email
            }
        });

        if (data) {
            if (data.error) {
                this.promocodeError = data.error;
            } else {
                this.promocodeInfo = data;
            }
        } else {
            this.promocodeError = this.$t("exchanger.checkPromocode.codeError");
            this.promocodeInfo = null;
        }
    } catch (e) {
        console.error(e);
    }
}

export default checkPromocode;
