<template>
    <div class="cell cell--4-col cell--4-col-tablet cell--4-col-phone card">
        <div class="info">
            <div class="status-text-with-number">
                <span class="number">{{ number }}</span>
                <span class="status-text" :class="status">{{ statusText }}</span>
            </div>

            <div class="status-text-with-fullName">
                <span class="fullName">{{ name }}</span>
            </div>

            <div class="date">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 1 24 24" fill="none" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock">
                    <circle cx="12" cy="12" r="10"></circle>
                    <polyline points="12 6 12 12 16 14"></polyline>
                </svg>
                {{
                    moment(card.created)
                        .lang($i18n.locale)
                        .format("DD MMMM YYYY, HH:mm")
                }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";

export default {
    props: {
        card: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        moment: moment
    },
    computed: {
        status() {
            switch (this.card.status) {
                case 101:
                    return "verification";
                case 102:
                    return "resolved";
                case 103:
                case 104:
                    return "rejected";

                default:
                    return "";
            }
        },
        number() {
            return `${this.card.number.replace(/\s+/g, "").replace(/(.{4})(.{4})(.{4})(.{4,6})/, "$1 $2 $3 $4")}`;
        },

        name() {
            return `${this.card.name}`;
        },

        statusText() {
            switch (this.status) {
                case "verification":
                    return this.$t("itemCard.statusName.new");
                case "resolved":
                    return this.$t("itemCard.statusName.received");
                case "rejected":
                    return this.$t("itemCard.statusName.rejected");
                default:
                    return "";
            }
        },
        date() {
            let d = new Date(this.card.created);
            d = ["0" + d.getDate(), "0" + (d.getMonth() + 1), "" + d.getFullYear(), "0" + d.getHours(), "0" + d.getMinutes()];
            for (let i = 0; i < d.length; i++) {
                if (i !== 2) {
                    d[i] = d[i].slice(-2);
                }
            }
            let monthName =
                d[1] === "01"
                    ? "января"
                    : d[1] === "02"
                    ? "февраля"
                    : d[1] === "03"
                    ? "марта"
                    : d[1] === "04"
                    ? "апреля"
                    : d[1] === "05"
                    ? "мая"
                    : d[1] === "06"
                    ? "июня"
                    : d[1] === "07"
                    ? "июля"
                    : d[1] === "08"
                    ? "августа"
                    : d[1] === "09"
                    ? "сентября"
                    : d[1] === "10"
                    ? "октября"
                    : d[1] === "11"
                    ? "ноября"
                    : "декабря";
            d[1] = monthName;
            return d.slice(0, 3).join(" ") + ", в " + d.slice(3).join(":");
        }
    }
};
</script>

<style lang="scss" scoped>
.card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-sm;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1%);
}

.info {
    padding: 6px 6px;
}

.status-text-with-number {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.status-text-with-fullName {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    font-weight: 400;
    color: $grafit;
}

.status-text-with-number .status-text {
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: $border-sm;
}

.status-text-with-number .number {
    color: $grafit;
    font-size: 20px;
    font-weight: 900;
}

.info .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $grafit;
    font-size: 14px;
}

.info .date svg {
    color: $grafit;
    margin-right: 8px;
}

.status-text.verification {
    background-color: $yellow;
}

.status-text.rejected {
    background-color: $red;
}

.status-text.resolved {
    background-color: $green;
}

.status-text.banned {
    background-color: $red;
}
</style>
