<template>
    <transition name="snack-bar">
        <div class="snack-bar" v-if="snackBar.active">
            {{ snackBar.text }}
        </div>
    </transition>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
    data() {
        return {
            delay: 3200,
            timerID: null
        };
    },
    computed: {
        ...mapGetters(["snackBar"])
    },
    created() {
        this.hideSnackBar();
    },
    watch: {
        snackBar: {
            deep: true,
            handler() {
                this.hideSnackBar();
            }
        }
    },
    methods: {
        ...mapMutations(["SET_SNACK_BAR"]),
        hideSnackBar() {
            if (this.snackBar.active) {

                if (this.timerID) {
                    clearTimeout(this.timerID);
                    this.timerID = null;
                }
                this.timerID = setTimeout(() => {
                    this.SET_SNACK_BAR({ active: false, text: "" });
                }, this.delay);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.snack-bar {
    display: flex;
    align-items: center;
    width: max-content;
    min-width: 288px;
    max-width: 568px;
    min-height: 48px;
    max-height: 80px;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    color: $white;
    font-size: 16px;
    font-weight: 300;
    background-color: $grey-lg-text;
    padding: 10px 24px 14px 24px;
    margin: 0 auto;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    z-index: 900;
    opacity: 0.92;
    transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.snack-bar-enter,
.snack-bar-leave-to {
    transform: translate3D(0, calc(100% + 8px), 0);
    opacity: 0;
}
@media (max-width: 479px) {
    .snack-bar {
        min-width: 288px;
        max-width: -webkit-fill-available;
        min-height: 48px;
        max-height: 150px;
    }
}
</style>
