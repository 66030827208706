export default function promocodeDiscount() {
    if (!this.exchange || !this.promocodeInfo) return 0;

    return 0;

    // let formula = this.currentExchange.rate.formula;

    // let precent = 0;
    // if (formula > 1) {
    //     precent = formula - 1;
    // } else if (formula < 1) {
    //     precent = 1 - formula;
    // }

    // let com = Math.floor(precent * 100);

    // let discount = 0;

    // let promocodeType = this.promocodeInfo.promocodeType;
    // if (promocodeType === "referrer") {
    //     if (com >= 3) {
    //         discount = 0.5;
    //     } else {
    //         discount = 0.1;
    //     }
    // } else if (promocodeType === "usual") {
    //     let { commissionFrom, commissionTo, discount: d } = this.promocodeInfo;
    //     if (commissionFrom <= com && commissionTo >= com) {
    //         discount = d;
    //     }
    // }
    // discount = discount / 100;

    // // console.log(discount, "discount");

    // return discount;
}
