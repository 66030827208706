<template>
    <div class="input-wrapper" :class="{ invalid: isInvalid, currency: isCurrency }">
        <div class="label" v-if="label && isLabel">{{ label }}</div>
        <div class="input" :class="{ invalid: isInvalid, currency: isCurrency, active: isActive }">
            <img alt="" :src="image" v-if="image" />
            <input
                :type="inputType"
                :placeholder="label"
                :value="value"
                v-on="numberic()"
                @input="changeInput"
                @paste="onPaste"
                @focus="
                    $emit('focus');
                    isActive = true;
                "
                @blur="
                    $emit('blur');
                    isActive = false;
                "
            />
            <span class="currency-code" v-if="currency.code">{{ currency.code }}</span>
        </div>

        <div class="validation-message" v-html="validation.text" v-if="isInvalid"></div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number],
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        inputType: {
            type: String,
            default: "text"
        },
        currency: {
            type: Object,
            default: () => ({})
        },
        validation: {
            type: Object,
            default: () => ({})
        },
        isCard: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isActive: false
        };
    },
    watch: {
        value() {
            // console.log(this.value, "watch value");
        }
    },
    computed: {
        image() {
            if (!this.objectEmpty(this.currency)) {
                return `${this.URL.server}/images/${this.currency.image}.png`;
            } else if (this.icon) {
                return `${this.URL.server}/${this.icon}.png`;
            } else {
                return undefined;
            }
        },
        isLabel() {
            return this.value.length !== 0;
        },
        isCurrency() {
            return !this.objectEmpty(this.currency);
        },
        isInvalid() {
            return !this.objectEmpty(this.validation) ? !this.validation.isValid : false;
        }
    },
    methods: {
        numberic() {
            if (this.isCurrency) {
                return { keypress: this.isNumber };
            } else if (this.isCard) {
                return { keypress: this.isNumber };
            } else {
                return {};
            }
            // this.isCurrency
            // isCurrency ?  : {}
        },
        changeInput(e) {
            let value = e.target.value;

            if (this.isCard) {
                let number = value + "";

                if (number) {
                    let ccnum = number.replace(/\s/g, "");
                    let ccnumspaced = ccnum.match(/.{1,4}/g);
                    let formattedNumber = ccnumspaced.join(" ");
                    value = formattedNumber;
                    if (value.length >= 24) {
                        value = value.substr(0, 22);
                    }
                }

                this.$emit("input", value);
            } else {
                this.$emit("input", value);
            }
        },
        isNumber(e) {
            e = e ? e : window.event;

            const charCode = e.code ? e.which : e.keyCode;
            let isDotRepeat = false,
                count,
                amount = e.target.value,
                dotIndex = amount.indexOf(".");

            if (~dotIndex) {
                if (charCode === 48 && dotIndex === 1) return;
                count = amount.length - dotIndex;

                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    count--;
                }
                if (charCode === 44) {
                    isDotRepeat = true;
                }
            }
            if (count > this.currency.precision) {
                e.preventDefault();
            } else if (isDotRepeat) {
                e.preventDefault();
            } else if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                e.preventDefault();
            } else {
                return true;
            }
        },
        onPaste() {
            // if (this.isCurrency) e.preventDefault();
        }
    }
};
</script>

<style lang="scss" scoped>
.input-wrapper {
    position: relative;
    margin: 16px 0;
}

.input-wrapper.invalid.currency {
    margin: 16px 0 8px 0;
}

.label {
    color: $grey-lg-text;
    font-size: 14px;
}

.input-wrapper.invalid .label {
    color: #e84c4c;
}

.input {
    height: 38px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border-bottom: 1.8px solid #cccccc;
    background: #ffffff !important; // желательно прописывать, так как в Chrome при сохранных данных оно может быть желтым
    outline: none; // удаляет обводку в браузерах хром(желтая) и сафари(синяя)
    color: $grafit; //цвет шрифта в обычном состоянии
}

.input.active {
    border-color: rgb(255, 153, 0);
}

.input.invalid {
    border-color: #e84c4c;
}

.input img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.input input {
    width: 100%;
    font-size: 16px;
    color: $cur-name;
    margin: 0;
    padding: 0 0 2px 0;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

input::-webkit-input-placeholder {
    color: $grey-lg-text;
}

.input.currency {
    height: 48px;
    padding: 0 0 7px 0;
}

.input.currency input {
    width: calc(100% - 64px);
    padding: 12px 16px;
}

.input input:focus {
    outline: none;
}

.input .currency-code {
    width: 32px;
    text-align: center;
    color: $code;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}

.input-wrapper.invalid .input:not(.currency) input {
    color: #e84c4c;
}

.input-wrapper.invalid .input input::placeholder {
    color: #e84c4c;
    opacity: 1;
}

.input-wrapper.invalid .input input:-ms-input-placeholder {
    color: #e84c4c;
}

.input-wrapper.invalid .input input::-ms-input-placeholder {
    color: #e84c4c;
}

.validation-message {
    color: #e84c4c;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
}

.validation-message:first-letter {
    text-transform: capitalize;
}

.validation-message > .amount {
    font-weight: 500;
}
</style>
