export default function getPromocodeTextInfo() {
    let amount = this.getPromocodeGiveAmount;
    if (!amount) return null;
    let exchange = this.exchange;
    if (!exchange) return null;
    let { code } = exchange.currency.give;

    let text = `Начисление по промокоду <b style="font-weight: bold">${this.promocodeInfo.code}</b> : <b style="color: green"> +${amount} ${code} </b>`;
    console.log(text);

    return text;
}
