<template>
    <div>
        <order-header :hideHandler="hideHandler" :name="$t('popupOrder.changeAmountOrder.title', { orderId: order._id })" />
        <div class="order-content">
            <div class="status-description">
                {{ $t("popupOrder.changeAmountOrder.desc1") }}
            </div>

            <order-pair :pair="pair" />
            <div class="border"></div>
            <div class="status-description">
                {{ $t("popupOrder.changeAmountOrder.desc2") }}
            </div>
            <div class="status-description">
                {{ $t("popupOrder.changeAmountOrder.desc3") }}
            </div>
            <order-pair v-if="exchange" :pair="changePair" />
            <div class="border"></div>
            <order-buttons :text1="$t('popupOrder.changeAmountOrder.confirm.cancel')" :text2="$t('popupOrder.changeAmountOrder.confirm.change')" :spinner="spinner1" :spinner2="spinner2" :func1="stayHandler" :func2="changeAmount" />
        </div>
    </div>
</template>

<script>
import orderHeader from "./orderHeader";
import orderPair from "./orderPair";
import orderButtons from "./orderButtons";

// Services
import ExchangeService from "../../services/ExchangeService";
import OrderService from "../../services/OrderService";

import { mapGetters, mapMutations } from "vuex";
//  comp

import getExchangeAmount from "../../utils/getExchangeAmount";
export default {
    data() {
        return {
            spinner1: false,
            spinner2: false,
            exchange: null
        };
    },
    mounted() {
        this.fetchExchange();
    },
    props: {
        hideHandler: Function,
        order: Object
    },
    components: {
        orderHeader,
        orderPair,
        orderButtons
    },
    methods: {
        ...mapMutations(["SET_CURRENT_ORDER", "SET_SNACK_BAR"]),
        async fetchExchange() {
            try {
                let response = await ExchangeService.getOne({ id: this.order.exchange });
                this.exchange = response.data;
            } catch (e) {
                console.error(e);
            }
        },
        stayHandler() {
            this.hideHandler();
            // this.SET_CURRENT_ORDER(Object.assign({}, this.order, { change: null }));
        },
        async changeAmount() {
            try {
                if (this.spinner2) return;
                this.spinner2 = true;

                let data = {
                    // id: this.order._id,
                    hash: this.order.hash,
                    amount: this.order.change.amount
                };

                let response = await OrderService.changeAmount(data);

                if (response) {
                    // this.SET_CURRENT_ORDER(response.data);
                    this.$router.push("/order/" + this.order.hash);
                    this.SET_SNACK_BAR({
                        active: true,
                        text: this.$t("popupOrder.changeAmountOrder.changeSuccess")
                    });
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.spinner2 = false;
            }
        }
    },
    computed: {
        ...mapGetters(["isAuthenticated"]),
        changePair() {
            let amount = +this.order.change.amount;
            let exchange = this.exchange;
            let sum = getExchangeAmount(exchange, "give", +amount);

            return {
                take: {
                    image: exchange.currency.take.image,
                    amount: amount,
                    code: exchange.currency.take.code,
                    name: exchange.currency.take.name
                },
                give: {
                    image: exchange.currency.give.image,
                    amount: sum,
                    code: exchange.currency.give.code,
                    name: exchange.currency.give.name
                }
            };
        },
        pair() {
            let order = this.order;
            return {
                take: {
                    image: order.currency.take.image,
                    amount: order.amount.take,
                    code: order.currency.take.code,
                    name: order.currency.take.name
                },
                give: {
                    image: order.currency.give.image,
                    amount: order.amount.give,
                    code: order.currency.give.code,
                    name: order.currency.give.name
                }
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.order-content {
    padding: 0px 16px 16px 16px;
}

.order-wrapper {
    min-width: 480px;
    max-width: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.header > .spinner {
    height: 32px;
    width: 32px;
    margin-right: 16px;
}

.header-name {
    font-size: 24px;
    line-height: 30px;
    color: $grafit;
}

.icon-close {
    cursor: pointer;
    color: $black;
}

.border {
    height: 1px;
    width: 100%;
    background-color: $cur--select;
    margin: 16px 0;
}

.order-step:not(:last-child) {
    margin-bottom: 16px;
    color: $grafit;
}

.order-step .icon-step {
    display: inline-block;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    color: $white;
    background-color: $orange;
    border-radius: 50%;
    margin-right: 8px;
}

.status-description {
    font-size: 16px;
    line-height: 24px;
}

.status-description .address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status-description .address .label {
    margin-right: 4px;
}

.status-description .address a {
    color: #4a76a8;
}

.button-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.castom-btn {
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    background-color: #3597fa;
    padding: 10px 16px;
    margin: 0;
    border-radius: 4px;
    box-shadow: 2px 2px 6px 0 rgba(53, 151, 250, 0.24);
    text-decoration: none;
    border: none;
    position: relative;
    overflow: hidden;
    color: #fff;

    &:hover {
        background: $orange radial-gradient(circle, transparent 1%, $orange 1%) center/15000%;
    }
}

@media (max-width: 739px) {
    .order-wrapper {
        min-width: 0;
    }
    .order-exchange .icon-exchange {
        text-align: center;
    }
}

.direction-description {
    margin: 10px 0px;
    font-weight: 400;
    color: #2d3540;
}

.order-warapper {
    min-width: 500px;
}

.description-resolve {
    font-weight: 600;
    margin-top: 24px;
}

.field-resolve {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px;
    flex-wrap: wrap;
    padding: 5px 0px;
    border-radius: 6px;
    margin-bottom: 10px;
}

.resolve-info {
    margin: 20px 0px;
}

.field-resolve span {
    font-size: 1.1rem;
    margin-right: 10px;
}

.resolve-btn {
    padding: 5px 10px;
}

@media (max-width: 560px) {
    .field-resolve {
        margin-bottom: 0px;
    }
    .resolve-btn {
        margin-top: 10px;
    }
}

.resolve-desc {
    font-size: 1.1rem;
}
.step-content {
    font-size: 16px;
    line-height: 32px;
    background-color: $cur--hover;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: $border-sm;
    border: $border-lg;
}
.field-name {
    color: #2a5885;
    margin-right: 16px;
}
.field-value {
    font-size: 16px;
    word-break: break-word;
}
.item-rpp {
    display: flex;
}
.copy-text {
    margin-left: 1rem;
    color: #3597fa;
}
.castom-btn {
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    background-color: $orange;
    padding: 10px 16px;
    margin: 0;
    border-radius: 4px;
    box-shadow: 2px 2px 6px 0 rgba(53, 151, 250, 0.24);
    text-decoration: none;
    border: none;
    position: relative;
    overflow: hidden;
    color: #fff;
    &:hover {
        background: $orange radial-gradient(circle, transparent 1%, $orange 1%) center/15000%;
    }
}
.svg-icon-copy {
    margin-left: 1rem;
    width: 28px;
    height: 28px;
}
.copy-icon > path:hover {
    fill: #3597fa;
}

.open-chat {
    color: #2d3540;
    margin: 20px 0px 0px 0px;
}

.open-chat span {
    cursor: pointer;
    color: #2a5885;
    text-decoration: underline;
}

//  <div class="qrcode-section" v-if="getQrCode">
//         <p class="qrcode-section-title">QR-код для оплаты</p>
//         <img class="qrcode-section-image" :src="getQrCode" />

.qrcode-section {
    background-color: white;
    width: max-content;
    margin: 10px auto;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.qrcode-section-title {
    text-align: center;
    color: #2a5885;
    font-size: 20px;
}
.qrcode-section-image {
    display: block;
    margin: 10px auto;
}
</style>
