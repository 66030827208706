import sumWithPrecision from "./sumWithPrecision";

export default function(exchange, get, amount, inRub = false) {
    let rate = exchange.rate;
    let reverse = rate.reverse;
    let service = rate.service;
    let take = exchange.currency.take;
    let give = exchange.currency.give;
    let precisionTake = take.precision;
    let precisionGive = give.precision;

    let result = 0;

    if (get == "give") {
        if (reverse === "left") {
            result = sumWithPrecision({ precision: precisionGive, sum: amount / service });
        } else if (reverse === "right") {
            result = sumWithPrecision({ precision: precisionGive, sum: service * amount });
        } else if (reverse === "none") {
            result = sumWithPrecision({ precision: precisionGive, sum: amount });
        }
        if (inRub) {
            result = result * give.evalue;
        }
    } else {
        if (reverse === "left") {
            result = sumWithPrecision({ precision: precisionTake, sum: amount * service });
        } else if (reverse === "right") {
            result = sumWithPrecision({ precision: precisionTake, sum: amount / service });
        } else if (reverse === "none") {
            result = sumWithPrecision({ precision: precisionTake, sum: amount });
        }
        if (inRub) {
            result = result * take.evalue;
        }
    }

    if (result && result > 0) {
        return result;
    } else {
        return 0;
    }
}
