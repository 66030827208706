export default function getCity() {
    let exchange = this.exchange;
    let take = exchange.currency.take;
    let give = exchange.currency.give;

    if (this.cityCodes.length == 0) return "";

    if (take && take.isCash && take.cityCashCode) {
        let find = this.cityCodes.find(c => c.code == take.cityCashCode);
        return find.name;
    }
    if (give && give.isCash && give.cityCashCode) {
        let find = this.cityCodes.find(c => c.code == give.cityCashCode);
        return find.name;
    }
}
