export default function() {
    let selectedTake = this.selected.take;
    let selectedGive = this.selected.give;

    if (selectedTake && selectedGive) {
        let exc = this.exchanges.find(e => e.take._id == this.selected.take._id && e.give._id === this.selected.give._id);
        if (this.exchange) {
            if (this.exchange._id != exc._id) {
                this.sockets.unsubscribe(`update_exchange_${this.exchange._id}`);
                this.fetchOneExchange(exc._id);
            }
        } else {
            this.fetchOneExchange(exc._id);
        }
    } else {
        this.exchange = null;
    }
}
