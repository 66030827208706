export default function image() {
    let exchange = this.exchange;
    let take = exchange.currency.take;
    let give = exchange.currency.give;

    return {
        take: `${this.URL.server}/images/${take.image}.png`,
        give: `${this.URL.server}/images/${give.image}.png`
    };
}
