import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

// Основные роуты страниц
import Exchanger from "./views/exchanger2/exchanger.vue";
// import Exchanger from "./views/exchanger.vue";
import Profile from "./views/profile.vue";
import Agreement from "./views/agreement.vue";
import AMLKYC from "./views/amlkyc.vue";
// import Faq from "./views/faq.vue";
// import News from "./views/news.vue";
import Contacts from "./views/contacts.vue";
import NotFound from "./views/notFound.vue";
import Partners from "./views/partners.vue";
import Acquiring from "./views/aсquiring.vue";
// Order
import { Order } from "./views/order";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "exchanger",
        component: Exchanger,
        meta: {
            page: 0
        }
    },
    {
        path: "/agreement",
        name: "agreement",
        component: Agreement,
        meta: {
            page: 1
        }
    },
    // {
    //     path: "/news",
    //     name: "news",
    //     component: News,
    //     meta: {
    //         page: 2
    //     }
    // },
    // {
    //     path: "/faq",
    //     name: "faq",
    //     component: Faq,
    //     meta: {
    //         page: 3
    //     }
    // },
    {
        path: "/contacts",
        name: "contacts",
        component: Contacts,
        meta: {
            page: 2
        }
    },

    {
        path: "/profile/:view?",
        name: "profile",
        component: Profile,
        meta: {
            page: 3,
            auth: true
        },
        props: true
    },

    {
        path: "/partners",
        name: "partners",
        component: Partners,
        meta: {
            page: 4
        }
    },
    {
        path: "/acquiring",
        name: "acquiring",
        component: Acquiring,
        meta: {
            page: 5
        }
    },

    {
        path: "*",
        component: NotFound,
        name: "404",
        meta: {
            page: 8
        }
    },
    {
        path: "/order/:hash",
        name: "order",
        component: Order,
        meta: {
            page: 6
        }
    },
    {
        path: "/aml-kyc",
        name: "aml-kyc",
        component: AMLKYC,
        meta: {
            page: 7
        }
    },
];

const router = new VueRouter({
    mode: "history",
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    },
    routes
});

// Проверка отображения роутов только для авторизованного пользователя

router.beforeEach((to, from, next) => {
    if (store.state.ui.loading) {
        store.commit("SET_LOADING", false);
    }
    if (to.matched.some(m => m.meta.auth)) {
        if (store.state.profile.isAuthenticated) {
            next();
        } else {
            next({ path: "/" });
        }
    } else {
        next();
    }
});

export default router;
