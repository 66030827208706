<template>
    <div class="reward-card">
        <div class="reward-flex">
            <p class="reward-sum">{{ new Intl.NumberFormat("ru-RU").format(reward.sum).trim() }} RUB</p>
            <div :class="statusClass">
                {{ statusText }}
            </div>
        </div>
        <div class="reward-field" v-for="field in reward.fields" :key="field._id">
            <span> {{ field.name }}</span
            >: <span> {{ field.value }}</span>
        </div>
        <div v-if="reward.status === 103" class="reward-comment">
            {{ $t("rewardCard.comment") }}
            <br />
            <span>{{ reward.comment }}</span>
        </div>
        <div class="reward-flex reward-flex-bottom">
            <div class="reward-date">
                {{ date }}
            </div>

            <div class="reward-type">
                {{ type }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        reward: Object
    },
    computed: {
        statusText() {
            switch (this.reward.status) {
                case 101:
                    return this.$t("withdrawalHistory.status.inProcess");
                case 102:
                    return this.$t("withdrawalHistory.status.paid");
                case 103:
                    return this.$t("withdrawalHistory.status.cancel");
                default:
                    return "";
            }
        },
        statusClass() {
            let classText = "reward-status";
            switch (this.reward.status) {
                case 101:
                    return classText + " " + "new";
                case 102:
                    return classText + " " + "resolve";
                case 103:
                    return classText + " " + "reject";
                default:
                    return classText;
            }
        },
        date() {
            console.log(this.reward, "created");
            let d = new Date(this.reward.created);
            d = ["0" + d.getDate(), "0" + (d.getMonth() + 1), "" + d.getFullYear(), "0" + d.getHours(), "0" + d.getMinutes()];
            for (let i = 0; i < d.length; i++) {
                if (i !== 2) {
                    d[i] = d[i].slice(-2);
                }
            }
            let monthName =
                d[1] === "01"
                    ? "января"
                    : d[1] === "02"
                    ? "февраля"
                    : d[1] === "03"
                    ? "марта"
                    : d[1] === "04"
                    ? "апреля"
                    : d[1] === "05"
                    ? "мая"
                    : d[1] === "06"
                    ? "июня"
                    : d[1] === "07"
                    ? "июля"
                    : d[1] === "08"
                    ? "августа"
                    : d[1] === "09"
                    ? "сентября"
                    : d[1] === "10"
                    ? "октября"
                    : d[1] === "11"
                    ? "ноября"
                    : "декабря";
            d[1] = monthName;
            return d.slice(0, 3).join(" ") + ", в " + d.slice(3).join(":");
        },
        type() {
            switch (this.reward.type) {
                case "cashback":
                    return this.$t("withdrawalHistory.typeName.cashback");
                case "referral":
                    return this.$t("withdrawalHistory.typeName.referral");
                default:
                    return "";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.reward-card {
    display: flex;
    min-width: calc(33.333333% - 10px);
    flex-direction: column;
    background-color: #ffffff;
    border-radius: $border-sm;
    margin: 15px 5px;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.reward-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.reward-flex-bottom {
    margin-bottom: 0px;
    margin-top: 10px;
    color: #2d3540;
    font-size: 14px;
}

@media (max-width: 1200px) {
    .reward-card {
        min-width: calc(50% - 10px);
    }
}

@media (max-width: 800px) {
    .reward-card {
        min-width: calc(100% - 10px);
    }
}

.reward-field {
    margin-bottom: 6px;
}

.reward-field span {
    color: #2d3540;
}

.reward-sum {
    color: #2d3540;
    font-size: 18px;
    font-weight: 500;
}

.reward-status {
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: $border-sm;
}

.new {
    background-color: #ffc200;
}
.resolve {
    background-color: #4bbd5c;
}

.reject {
    background-color: #d0021b;
}

.reward-comment {
    margin-top: 10px;
}

.reward-comment span {
    color: red;
    font-size: 1.2rem;
    display: block;
    margin-top: 6px;
}
</style>
<template>
    <div class="reward-card">
        <div class="reward-flex">
            <p class="reward-sum">{{ reward.sum }} руб.</p>
            <div :class="statusClass">
                {{ statusText }}
            </div>
        </div>
        <div class="reward-field" v-for="field in reward.fields" :key="field._id">
            <span> {{ field.name }}</span
            >: <span> {{ field.value }}</span>
        </div>
        <div v-if="reward.status === 103" class="reward-comment">
            Комментарий сервиса:
            <br />
            <span>{{ reward.comment }}</span>
        </div>
        <div class="reward-flex reward-flex-bottom">
            <div class="reward-date">
                {{ date }}
            </div>

            <div class="reward-type">
                {{ type }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        reward: Object
    },
    computed: {
        statusText() {
            switch (this.reward.status) {
                case 101:
                    return "В обработке";
                case 102:
                    return "Выплачена";
                case 103:
                    return "Отклоненна";
                default:
                    return "";
            }
        },
        statusClass() {
            let classText = "reward-status";
            switch (this.reward.status) {
                case 101:
                    return classText + " " + "new";
                case 102:
                    return classText + " " + "resolve";
                case 103:
                    return classText + " " + "reject";
                default:
                    return classText;
            }
        },
        date() {
            console.log(this.reward, "created");
            let d = new Date(this.reward.created);
            d = ["0" + d.getDate(), "0" + (d.getMonth() + 1), "" + d.getFullYear(), "0" + d.getHours(), "0" + d.getMinutes()];
            for (let i = 0; i < d.length; i++) {
                if (i !== 2) {
                    d[i] = d[i].slice(-2);
                }
            }
            let monthName =
                d[1] === "01"
                    ? "января"
                    : d[1] === "02"
                    ? "февраля"
                    : d[1] === "03"
                    ? "марта"
                    : d[1] === "04"
                    ? "апреля"
                    : d[1] === "05"
                    ? "мая"
                    : d[1] === "06"
                    ? "июня"
                    : d[1] === "07"
                    ? "июля"
                    : d[1] === "08"
                    ? "августа"
                    : d[1] === "09"
                    ? "сентября"
                    : d[1] === "10"
                    ? "октября"
                    : d[1] === "11"
                    ? "ноября"
                    : "декабря";
            d[1] = monthName;
            return d.slice(0, 3).join(" ") + ", в " + d.slice(3).join(":");
        },
        type() {
            switch (this.reward.type) {
                case "cashback":
                    return "Кэшбэк";
                case "referral":
                    return "Реферральная программа";
                default:
                    return "";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.reward-card {
    display: flex;
    min-width: calc(33.333333% - 10px);
    flex-direction: column;
    background-color: #ffffff;
    border-radius: $border-sm;
    margin: 15px 5px;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.reward-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.reward-flex-bottom {
    margin-bottom: 0px;
    margin-top: 10px;
    color: #2d3540;
    font-size: 14px;
}

@media (max-width: 1200px) {
    .reward-card {
        min-width: calc(50% - 10px);
    }
}

@media (max-width: 800px) {
    .reward-card {
        min-width: calc(100% - 10px);
    }
}

.reward-field {
    margin-bottom: 6px;
}

.reward-field span {
    color: #2d3540;
}

.reward-sum {
    color: #2d3540;
    font-size: 18px;
    font-weight: 500;
}

.reward-status {
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: $border-sm;
}

.new {
    background-color: #ffc200;
}
.resolve {
    background-color: #4bbd5c;
}

.reject {
    background-color: #d0021b;
}

.reward-comment {
    margin-top: 10px;
}

.reward-comment span {
    color: red;
    font-size: 1.2rem;
    display: block;
    margin-top: 6px;
}
</style>
