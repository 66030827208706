import fetchExchangers from "./fetchExchangers";
import setTakeFilterList from "./setTakeFilterList";
import sortCurrencies from "./sortCurrencies";
import initGiveActiveFilter from "./initGiveActiveFilter";
import initSelectedGive from "./initSelectedGive";
import changeExchange from "./changeExchange";
import fetchOneExchange from "./fetchOneExchange";
import fetchSiteCodes from "./fetchSiteCodes";
import setValidationFields from "./setValidationFields";
import formatEmail from "./formatEmail";
import resetValidateField from "./resetValidateField";
import inputTake from "./inputTake";
import inputGive from "./inputGive";
import calculateTake from "./calculateTake";
import calculateGive from "./calculateGive";
import getExchangeAmount from "../../../utils/getExchangeAmount";
import mobileNumberHandler from "./mobileNumberHandler";
import getImgUrl from "./getImgUrl";
import validationFields from "./validationFields";
import rateUpdate from "./rateUpdate";
import validateInputTake from "./validateInputTake";
import validateInputGive from "./validateInputGive";
import getCommissionBTC from "./getCommissionBTC";
import checkPromocode from "./checkPromocode";
import fetchBreak from "./fetchBreak";
import submitData from "./submitData";
import validateEmail from "./validateEmail";
import setFieldsError from "./setFieldsError";
import clearFields from "./clearFields";
import createOrder from "./createOrder";
import setExchangeFields from "./setExchangeFields";

export default {
    setExchangeFields,
    createOrder,
    clearFields,
    setFieldsError,
    validateEmail,
    submitData,
    fetchBreak,
    getCommissionBTC,
    validateInputTake,
    validateInputGive,
    rateUpdate,
    validationFields,
    getImgUrl,
    mobileNumberHandler,
    getExchangeAmount,
    inputTake,
    inputGive,
    calculateTake,
    calculateGive,
    formatEmail,
    resetValidateField,
    setValidationFields,
    fetchExchangers,
    fetchSiteCodes,
    fetchOneExchange,
    setTakeFilterList,
    sortCurrencies,
    initGiveActiveFilter,
    initSelectedGive,
    changeExchange,
    checkPromocode
};
