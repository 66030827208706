<template lang="">
    <div class="orderPaymentSection">
        <StepItem :number="1" :text="$t('orderPage.payment.step1')" />
        <QiwiChoosePayment v-if="isQiwi && hasLinkAndFields" :changeShowQiwiPaymentInfo="changeChoose" />
        <Sum :sum="+order.amount.take" />
        <div class="grid">
            <div :class="{ 'cell cell--4-col-tablet cell--4-col-phone': true, [qrCodeValue ? 'cell--7-col' : 'cell--12-col']: true }">
                <Fields v-if="showFields && hasFields" :fields="order.fields.info" />
            </div>
            <div v-if="qrCodeValue" class="cell cell--5-col cell--4-col-tablet cell--4-col-phone">
                <div class="orderPaymentSection_qrLayout">
                    <div class="orderPaymentSection_qrWrap">
                        <qrcode-vue class="qr-code-section__image" :value="qrCodeValue" :size="250" level="H" />
                    </div>
                </div>
            </div>
        </div>

        <PaymentLinks v-if="showLinks" :links="links" />

        <div v-if="paymentNotify" class="orderPaymentSection_notify">
            {{ paymentNotify }}
        </div>

        <div class="orderPaymentSection_chat">
            {{ $t("popupOrder.paymentOrder.chatQuest") }}
            <span @click="openChat">
                {{ $t("popupOrder.paymentOrder.chatBtn") }}
            </span>
        </div>
        <StepItem v-if="!hideConfirm" :number="2" :text="$t('popupOrder.paymentOrder.step2')" />
        <StepItem v-if="hideConfirm" :number="2" :text="$t('popupOrder.paymentOrder.step2hideConfirm')" />
        <div class="orderPaymentSection_btns">
            <my-button class="outline" :outline="true" :value="$t('popupOrder.paymentOrder.action.cancel')" @click.native="rejectedHandler" :spinner="spinner1"></my-button>
            <my-button v-if="!hideConfirm" :value="$t('popupOrder.paymentOrder.action.iPaid')" @click.native="paymentHandler" :spinner="spinner2"></my-button>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import StepItem from "../../../../components/ui/StepItem.vue";
import MyButton from "../../../../components/ui/button.vue";

import Fields from "./Fields.vue";
import PaymentLinks from "./PaymentLinks.vue";
import QiwiChoosePayment from "./QiwiChoosePayment.vue";
import Sum from "./Sum.vue";
import QrcodeVue from "qrcode.vue";
import OrderService from "../../../../services/OrderService";
import axios from "axios";

export default {
    data() {
        return {
            choose: "",
            spinner1: false,
            spinner2: false
        };
    },
    props: {
        order: Object,
        setOrder: Function
    },
    components: {
        QrcodeVue,
        StepItem,
        Fields,
        PaymentLinks,
        QiwiChoosePayment,
        MyButton,
        Sum
    },
    computed: {
        ...mapGetters(["isAuthenticated"]),
        paymentNotify() {
            let text = this?.order?.currency?.take?.currency?.paymentNotification;
            return text;
        },
        hideConfirm() {
            return this?.order?.viewSettings?.hidePaymentConfirmBtn;
        },
        hasFields() {
            return !!this.order.fields.info.length;
        },
        showLinks() {
            let paymentLinks = this.links;

            let hasLinks = paymentLinks && paymentLinks.length;

            if (!hasLinks) return false;

            if (this.isQiwi && this.hasLinkAndFields) {
                return this.choose == "links";
            } else {
                return true;
            }
        },
        showFields() {
            if (this.isQiwi && this.hasLinkAndFields) {
                return this.choose == "fields";
            } else {
                return true;
            }
        },
        isQiwi() {
            let takeCurrency = this.order.currency.take.currency;
            return takeCurrency && takeCurrency.uniqueType && takeCurrency.uniqueType === "qiwi";
        },
        hasLinkAndFields() {
            let hasInfo = this.order.fields.info.length !== 0;
            return hasInfo && this.links && !!this.links.length;
        },
        links() {
            let order = this.order;
            let paymentLinks = order.paymentLinks;
            let hasPaymentLinks = paymentLinks && paymentLinks.length;

            if (!hasPaymentLinks) return;

            let firstLink = paymentLinks[0];

            if (firstLink.includes("qiwi.com")) {
                return paymentLinks;
            } else {
                let resultLink = `${this.URL.api}${firstLink}?orderId=${order._id}`.replace(/([^:]\/)\/+/g, "$1");
                return [resultLink];
            }
        },
        qrCodeValue() {
            let isAddressFields = this.order.fields.info.filter(f => f.isAddress);
            return isAddressFields.length == 1 ? isAddressFields[0].value : null;
        }
    },
    methods: {
        changeChoose(v) {
            this.choose = v == this.choose ? "" : v;
        },
        openChat() {
            // window.jivo_api.open();
            window.Chatra("openChat");
            axios.post("/click-handler", {
                hash: this.order.hash,
                code: "can-not-pay"
            });
            return false;
        },
        paymentHandler() {
            if (this.spinner2) return;
            this.spinner2 = true;
            let data = { hash: this.order.hash, type: "payment" };
            this.changeOrder(data);
        },
        rejectedHandler() {
            if (this.spinner1) return;
            this.spinner1 = true;
            let data = { hash: this.order.hash, type: "reject" };
            this.changeOrder(data);
        },
        async changeOrder(data) {
            try {
                let { data: order } = await OrderService.change(data);

                if (order) {
                    this.setOrder(order);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.spinner1 = false;
                this.spinner2 = false;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.orderPaymentSection {
    background-color: #0000000d;
    padding: 18px 22px;
    border-radius: 8px;
    margin-top: 20px;
}
.orderPaymentSection_chat {
    margin: 10px 0px 20px;
}

.orderPaymentSection_chat span {
    cursor: pointer;
    color: #ff8c00;
}
.orderPaymentSection_chat span:hover {
    text-decoration: underline;
}

.orderPaymentSection_notify {
    color: #ff8c00;
    margin: 15px 0px;
    background-color: white;
    border-radius: 10px;
    padding: 10px 15px;
}

.orderPaymentSection_qrLayout {
    display: flex;
    justify-content: center;
    align-items: center;
}

.orderPaymentSection_qrWrap {
    background-color: white;
    padding: 10px;

    width: max-content;

    border-radius: 5px;
}

.orderPaymentSection_btns {
    display: flex !important;
    justify-content: center;
    margin-top: 20px;
}

.orderPaymentSection_btns button:first-child {
    margin-right: 10px;
    display: inline-block;
}
.orderPaymentSection_btns button:last-child {
    margin-left: 10px;
    display: inline-block;
}
</style>
