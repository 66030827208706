export default function inputTake(amount) {
    let a = +amount;

    if (!a) {
        a = 0;
        this.amount.take = a;
    }

    console.log(a, "take amount");

    this.calculateGive(a);

    this.lastInputActive = "take";
}
