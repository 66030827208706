<template lang="">
    <div class="orderCardReverify">
        <div class="grid">
            <div class="cell cell--5-col cell--4-col-tablet cell--4-col-phone ">
                <StepItem :number="1" :text="$t('popupCard.rejectedCard.step1')" />
                <ContentDescription :number="number" />
                <ContentComment :comment="card.comment.client" />
            </div>
            <div class="cell cell--7-col cell--4-col-tablet cell--4-col-phone ">
                <StepItem :number="2" :text="addImageText" />
                <CardPhotoExample v-if="!image" />
                <ImageSelect :image="image" :file="file" :removeImage="removeImage" :isScreenMobile="isScreenMobile" :setImage="setImage" :setFile="setFile" />
                <my-button class="orderCardReverify_sbmt" :value="$t('popupCard.rejectedCard.confirm.send')" @click.native="sendVerifyHandler" :spinner="spinner" />
            </div>
        </div>
    </div>
</template>
<script>
import StepItem from "../../../../components/ui/StepItem.vue";
import ContentDescription from "../../../../components/Card/RejectedOrderCard/ContentDescription.vue";
import ContentComment from "../../../../components/Card/RejectedOrderCard/ContentComment.vue";
import CardPhotoExample from "../../../../components/Card/UI/CardPhotoExample.vue";
import ImageSelect from "../../../../components/ui/ImageSelect.vue";
import addImageText from "../../../../components/Card/computeds/addImageText";
import isScreenMobile from "../../../../components/Card/computeds/isScreenMobile";
import MyButton from "../../../../components/ui/button.vue";
import CardService from "../../../../services/CardService";

export default {
    data() {
        return {
            file: null,
            image: null,
            spinner: false
        };
    },
    props: {
        card: Object
    },
    computed: {
        isScreenMobile: isScreenMobile,
        addImageText: addImageText
    },
    methods: {
        removeImage() {
            this.file = null;
            this.image = null;
        },
        setImage(i) {
            this.image = i;
        },
        setFile(f) {
            this.file = f;
        },
        async sendVerifyHandler() {
            try {
                if (this.spinner) return;
                if (!this.file) return;
                this.spinner = true;

                let form = new FormData();
                form.append("image", this.file);
                // form.append("id", this.card._id);
                await CardService.reverify(form, this.card.hash);
            } catch (err) {
                console.error(err);
            } finally {
                this.spinner = false;
            }
        }
    },
    beforeDestroy() {
        this.image = null;
        this.file = null;
        this.spinner = false;
    },
    components: {
        CardPhotoExample,
        ImageSelect,
        StepItem,
        ContentDescription,
        ContentComment,
        MyButton
    }
};
</script>
<style lang="scss" scoped>
.orderCardReverify {
    background-color: #0000000d;
    // padding: 18px 22px;
    border-radius: 8px;
    margin-top: 20px;
}
.orderCardReverify_sbmt {
    width: 100%;
    margin-top: 20px;
}
</style>
