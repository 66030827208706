import axios from "axios";

class CardService {
    getAll() {
        return axios.get("profile/cards");
    }
    reverify(data, hash) {
        // +
        return axios.put(`profile/cards/reverification?hash=${hash}`, data);
    }
    // reverify(data, auth) {
    //     if (auth) {
    //         return axios.put("profile/cards/reverification", data);
    //     } else {
    //         let orderToken = localStorage.getItem("orderToken");

    //         if (orderToken) {
    //             return axios.put("profile/card-reverifiction", data, {
    //                 headers: {
    //                     orderToken: orderToken
    //                 }
    //             });
    //         }
    //     }
    // }
    add(data) {
        return axios.post("profile/cards", data);
    }
}

const cardService = new CardService();

export default cardService;
