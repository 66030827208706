import filterData from "../../../lists/currenciesFilter.json";

export default function() {
    let filter = {
        rub: false,
        usd: false,
        eur: false,
        coin: false,
        ps: false,
        fiat: false
    };

    let exchanges = this.exchanges;
    exchanges.forEach(e => {
        let type = e.take.type;
        filter[type] = true;
    });

    let takeFilterList = filterData.filter(i => filter[i.id]);

    let findExc = null;

    if (this.pairLink) {
        let { cur_from, cur_to } = this.pairLink;
        findExc = this.exchanges.find(e => e.take.bestchangeCode == cur_from && e.give.bestchangeCode == cur_to);
        this.pairLink.exc = findExc;
    }
    if (findExc) {
        this.takeActiveFilter = findExc.take.type;
    } else {
        this.takeActiveFilter = takeFilterList[0].id;
    }

    this.takeFilterList = takeFilterList;
}
