<template>
    <header class="header-wrap" :class="{ 'is-profile': isProfile }">
        <loading v-show="isLoading"></loading>

        <nav class="navigation" v-if="isDesktop">
            <router-link class="logo flex" :to="{ name: 'exchanger' }" tag="div">
                <img width="30px" src="../assets/logo.png" />
                <p>rypik</p>
            </router-link>
            <div class="spacer"></div>

            <div class="flex">
                <router-link class="navigation-item" :to="{ name: 'agreement' }" tag="div">{{ $t("header.termsOfUse") }} </router-link>
                <router-link class="navigation-item" :to="{ name: 'aml-kyc' }" tag="div">{{ $t("header.amlkyc") }}</router-link>
                <!-- <router-link class="navigation-item" :to="{ name: 'news' }" tag="div">Новости</router-link>
                <router-link class="navigation-item" :to="{ name: 'faq' }" tag="div">FAQ</router-link> -->
                <router-link class="navigation-item" :to="{ name: 'partners' }" tag="div">{{ $t("header.partnership") }}</router-link>
                <router-link class="navigation-item" :to="{ name: 'contacts' }" tag="div">{{ $t("header.contacts") }}</router-link>
                <router-link class="navigation-item" :to="{ name: 'acquiring' }" tag="div">{{ $t("header.acquiring") }}</router-link>
            </div>
            <div class="spacer"></div>
            <LangSwithcer />
            <div class="navigation-sign">
                <div class="flex" @click.stop="openAuth('signIn')" v-if="!isAuthenticated">
                    <div class="icon">
                        <img src="../assets/arrow.svg" alt="" style="width: 32px" />
                    </div>
                    <div class="sign-name">
                        {{ $t("header.login") }}
                    </div>
                </div>
            </div>
            <div v-if="!isAuthenticated" class="slang">
                {{ $t("header.authOr") }}
            </div>
            <div @click.stop="changeView('signUp'), openAuth('signUp')">
                <my-button :value="$t('header.reg')" v-if="!isAuthenticated"></my-button>
            </div>

            <router-link class="navigation-user" :to="{ name: 'profile', params: { view: 'orders' } }" tag="div" :title="$t('header.myProfile')" v-if="isAuthenticated && isUser">
                <div class="avatar">
                    <img src="../assets/man.svg" alt="" style="width: 32px" />
                </div>

                <div class="user-name">{{ userName }}</div>
            </router-link>
        </nav>

        <nav class="navigation" v-if="!isDesktop">
            <router-link class="logo" :to="{ name: 'exchanger' }" tag="div">
                <img width="30px" src="../assets/logo.png" />
                <p>rypik</p></router-link
            >

            <div class="spacer"></div>

            <LangSwithcer />

            <div class="icon-hamburger" @click.stop="toggleMobileMenu">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu">
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                </svg>
            </div>
        </nav>

        <div class="navigation-profile-wrap" v-if="isProfile">
            <nav class="navigation-profile">
                <router-link class="navigation-profile-item" :to="{ name: 'profile', params: { view: 'orders' } }" tag="div">
                    <div class="icon">
                        <img src="../assets/exchange.svg" alt="" style="width: 32px" />
                    </div>
                    <div>
                        {{ $t("header.myOrders") }}
                    </div>
                </router-link>

                <router-link class="navigation-profile-item" :to="{ name: 'profile', params: { view: 'cards' } }" tag="div">
                    <div class="icon">
                        <img src="../assets/credit-card.svg" alt="" style="width: 32px" />
                    </div>
                    <div>
                        {{ $t("header.myCards") }}
                    </div>
                </router-link>

                <router-link class="navigation-profile-item" :to="{ name: 'profile', params: { view: 'referral' } }" tag="div">
                    <div class="icon">
                        <img src="../assets/referral.svg" alt="" style="width: 32px" />
                    </div>
                    <div>{{ $t("header.referralProgram") }}</div>
                </router-link>

                <router-link class="navigation-profile-item" :to="{ name: 'profile', params: { view: 'settings' } }" tag="div">
                    <div class="icon">
                        <img src="../assets/settings.svg" alt="" style="width: 32px" />
                    </div>
                    <div>
                        {{ $t("header.myProfile") }}
                    </div>
                </router-link>

                <div class="navigation-profile-item" @click="logout">
                    <div class="icon">
                        <img src="../assets/arrow.svg" alt="" style="width: 32px" />
                    </div>
                    <div>
                        {{ $t("header.logout") }}
                    </div>
                </div>
            </nav>
        </div>

        <div class="navigation-mobile" ref="mobileMenu" v-click-outside="closeMobileMenu" v-if="!isDesktop">
            <router-link class="navigation-item" :to="{ name: 'agreement' }" tag="div" @click.native="closeMobileMenu">
                {{ $t("header.termsOfUse") }}
            </router-link>
            <router-link class="navigation-item" :to="{ name: 'aml-kyc' }" tag="div" @click.native="closeMobileMenu">
                {{ $t("header.amlkyc") }}
            </router-link>
            <!-- <router-link class="navigation-item" :to="{ name: 'news' }" tag="div" @click.native="closeMobileMenu">
                Новости
            </router-link>
            <router-link class="navigation-item" :to="{ name: 'faq' }" tag="div" @click.native="closeMobileMenu">FAQ </router-link> -->
            <router-link class="navigation-item" :to="{ name: 'partners' }" tag="div" @click.native="closeMobileMenu">
                {{ $t("header.partnership") }}
            </router-link>
            <router-link class="navigation-item" :to="{ name: 'contacts' }" tag="div" @click.native="closeMobileMenu">
                {{ $t("header.contacts") }}
            </router-link>

            <router-link class="navigation-item" :to="{ name: 'acquiring' }" tag="div" @click.native="closeMobileMenu">
                {{ $t("header.acquiring") }}
            </router-link>

            <div class="navigation-sign" @click.stop="openAuth('signIn')" v-if="!isAuthenticated">
                <div class="icon">
                    <img src="../assets/arrow.svg" alt="" style="width: 32px" />
                </div>
                <div class="sign-name">
                    {{ $t("header.login") }}
                </div>
            </div>

            <div class="registr" @click.stop="changeView('signUp'), openAuth('signUp')">
                <my-button :value="$t('header.reg')" v-if="!isAuthenticated"></my-button>
            </div>
            <router-link class="navigation-user" :to="{ name: 'profile', params: { view: 'orders' } }" tag="div" :title="$t('header.myProfile')" @click.native="closeMobileMenu" v-if="isAuthenticated && isUser">
                <div class="avatar">
                    <img src="../assets/man.svg" alt="" style="width: 32px" />
                </div>
                <div class="user-name">
                    {{ userName }}
                </div>
            </router-link>
        </div>

        <auth :view="authorization.view" @close-auth="closeAuth" @change-view="changeView" ref="auth"></auth>
    </header>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import loading from "./loading.vue";
import authorization from "./authorization.vue";
import button from "./ui/button.vue";
import LangSwithcer from "./LangSwithcer.vue";

export default {
    components: {
        loading,
        auth: authorization,
        myButton: button,
        LangSwithcer
    },
    data() {
        return {
            mobileMenu: false,
            authorization: {
                open: false,
                view: "signIn"
            }
        };
    },
    watch: {},
    computed: {
        ...mapGetters(["isLoading", "isDesktop", "isAuthenticated", "user"]),

        isProfile() {
            return this.$route.name === "profile";
        },
        isUser() {
            return !!this.user;
        },
        userName() {
            return this.user.name || this.user.email;
        }
    },
    methods: {
        ...mapMutations({
            logoutUser: "LOGOUT_USER"
        }),
        toggleMobileMenu() {
            if (this.mobileMenu) {
                this.$refs.mobileMenu.style.height = "0";
                this.mobileMenu = false;
            } else {
                this.$refs.mobileMenu.style.height = "auto";
                this.mobileMenu = true;
            }
        },
        closeMobileMenu() {
            if (this.mobileMenu) {
                this.mobileMenu = false;
                this.$refs.mobileMenu.style.height = "0";
            }
        },
        openAuth(view) {
            if (!this.authorization.open) {
                this.authorization.view = view;
                if (this.isDesktop) {
                    this.$refs.auth.$el.style.width = "520px";
                    this.$refs.auth.$el.style.height = "100%";
                } else {
                    this.$refs.auth.$el.style.width = "100%";
                    this.$refs.auth.$el.style.height = "100%";
                }
                this.authorization.open = true;
            }
        },
        closeAuth() {
            if (this.authorization.open) {
                this.$refs.auth.$el.style.width = "0px";
                this.$refs.auth.$el.style.height = "0%";
                this.authorization.open = false;
            }
        },
        changeView(view) {
            this.authorization.view = view;
        },
        logout() {
            this.logoutUser();
            this.$router.push("/");
        }
    }
};
</script>

<style lang="scss" scoped>
.header-wrap {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    margin: 0 auto;
    height: 64px;
    z-index: 998;
    background-color: $white;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
    transition: height 0.35s;
}

.header-wrap.is-profile {
    height: 128px;
}

.navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    height: 64px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
    overflow: hidden;
}

.navigation-profile-wrap {
    width: 100%;
    height: 64px;
    box-shadow: 0 -1px 3px -2px #ff8700d1;
}

.navigation-profile {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto;
}

.navigation .logo {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    cursor: pointer;
    color: #727272;
    font-family: Whitney, Proxima Nova, sans-serif;
    font-size: 32px;
    font-weight: 400;
    margin-right: 32px;
    display: flex;
    align-items: center;
}

.navigation .logo p {
    font-weight: 700;
    margin-left: 2px;
}

.navigation-item,
.navigation-profile-item {
    font-family: "Gotham Pro", -apple-system, system-ui, BlinkMacSystemFont, “Segoe UI”, Roboto, “Helvetica Neue”, Arial, sans-serif;
    position: relative;
    display: inline;
    text-align: end;
    height: 64px;
    line-height: 64px;
    font-size: 16px;
    color: $grafit;
    margin: 0 16px;
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.navigation-item:hover:after,
.navigation-profile-item:hover:after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.navigation-item.router-link-active,
.navigation-profile-item.router-link-active {
    color: $dark-orange;
}

.navigation-item.router-link-active:after,
.navigation-profile-item.router-link-active:after {
    height: 2px;
    width: 100%;
    display: block;
    content: "";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.navigation-profile-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    color: $black;
    line-height: inherit;
    padding-right: 2px;
}

.navigation-profile-item .icon {
    margin-right: 16px;
}

.navigation-sign {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    color: $grafit;
    border-radius: 4px;
}

.navigation-sign .icon {
    margin-right: 12px;
}

.navigation-user {
    cursor: pointer;
    position: relative;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
}

.navigation-user:hover:after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.slang {
    margin: 0 0.5rem;
    font-size: 14px;
    font-weight: 400;
}

.flex {
    display: flex;
    text-align: end;
    align-items: center;
}

.sign-name {
    font-weight: 600;
}

.registr {
    margin-bottom: 1rem;
}

.navigation-user.router-link-active:after {
    height: 2px;
    width: 100%;
    display: block;
    content: "";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.navigation-user .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 42px;
    color: $grafit;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $page-wrap;
    border: 1px solid $orange;
    margin-right: 16px;
    border-radius: 50%;
}

.navigation-user .user-name {
    color: $grafit;
    font-weight: 500;
}

/* Mobile styles */
.navigation .icon-hamburger {
    cursor: pointer;
    color: $grey-lg-text;
}

.navigation-mobile {
    width: 100%;
    height: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 64px;
    left: 0;
    background-color: $grey;
    padding: 0;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32), 0 -2px 2px -2px rgba(0, 0, 0, 0.32);
    overflow-x: auto;
    overflow-y: hidden;
    transition: height 2s;
    z-index: 999;
}

.navigation-mobile .navigation-sign {
    flex-direction: row;
    margin: 8px 0 24px 0;
}

.navigation-mobile .navigation-user {
    margin: 0 16px 24px 16px;
}
</style>
