import ExchangeService from "../../../services/ExchangeService";

export default async function(id) {
    try {
        this.blurSection = true;
        let response = await ExchangeService.getOne({ id });
        let exchange = response.data;

        this.exchange = exchange;

        this.blurSection = false;
    } catch (e) {
        console.error(e);
    } finally {
        this.SET_LOADING(false);
    }
}
