let mode = process.env.NODE_ENV;

let isProd = mode === "production";

let URL = {
    main: isProd ? "http://localhost:8081/" : "http://localhost:8080/",
    api: isProd ? "https://api.crypik.com/api/" : "http://localhost:3000/api/",
    server: isProd ? "https://api.crypik.com" : "http://localhost:3000",
    client: "https://crypik.com/"
};

export { URL };
