export default function(amount) {
   

    let amountTake = +amount;

    let give = this.getExchangeAmount(this.exchange, "give", amountTake);

    const commissionBTC = this.getCommissionBTC(this.amount.take, give, "take");

    if (commissionBTC) {
        const calcAmountGive = Math.floor((give - commissionBTC) * this.precision.give) / this.precision.give;
        if (calcAmountGive > 0) {
            give = calcAmountGive;
        } else {
            give = 0;
        }
    }

    let commissionQiwi = this.getCommissionQiwi;

    if (commissionQiwi) {
        const subGive = give * commissionQiwi;
        const calcAmountGive = Math.floor((give - subGive) * this.precision.give) / this.precision.give;
        if (calcAmountGive > 0) {
            give = calcAmountGive;
        } else {
            give = 0;
        }
    }
    let promocodeSum = this.getPromocodeGiveAmount;

    if (promocodeSum) {
        const calcAmountGive = Math.floor((give + promocodeSum) * this.precision.give) / this.precision.give;
        if (calcAmountGive > 0) {
            give = calcAmountGive;
        } else {
            give = 0;
        }
    }

    this.amount.give = give;
}
