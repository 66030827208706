<template>
    <div class="acquaring_page">
        <div class="content">
            <div class="card section top_content">
                <div>
                    <h5 class="title">
                        {{ $t("acquiring.top_content.title") }}
                    </h5>
                    <p v-html="$t('acquiring.top_content.desc')" class="desc" />

                    <my-button class="top_content_btn" @click.native="scrollToForm" :value="$t('acquiring.top_content.btn')"></my-button>
                </div>
            </div>

            <div class="section features">
                <h5 class="main_title">
                    {{ $t("acquiring.features.title") }}
                </h5>
                <div class="features_cards">
                    <div class="card card_info">
                        <Icon color="#ff8c00" icon="carbon:security" height="44" />
                        <p class="card_info_title" v-html="$t('acquiring.features.card1')" />
                    </div>
                    <div class="card card_info bottom_self">
                        <Icon color="#ff8c00" icon="la:percentage" height="44" />
                        <p class="card_info_title" v-html="$t('acquiring.features.card2')" />
                    </div>

                    <div class="card card_info ">
                        <Icon color="#ff8c00" icon="bx:support" height="44" />
                        <p class="card_info_title" v-html="$t('acquiring.features.card3')" />
                    </div>
                    <div class="card card_info bottom_self">
                        <Icon color="#ff8c00" icon="mdi:credit-card-fast-outline" height="44" />
                        <p class="card_info_title" v-html="$t('acquiring.features.card4')" />
                    </div>
                </div>
            </div>

            <div class="section sphere">
                <h5 class="main_title">
                    {{ $t("acquiring.sphere.title") }}
                </h5>
                <div class="sphere_cards">
                    <div class="card card_sphere">
                        <Icon color="#ff8c00" icon="system-uicons:window-content" height="44" />
                        <p class="card_sphere_title">
                            {{ $t("acquiring.sphere.card1") }}
                        </p>
                    </div>
                    <div class="card card_sphere">
                        <Icon color="#ff8c00" icon="bx:store" height="44" />
                        <p class="card_sphere_title">
                            {{ $t("acquiring.sphere.card2") }}
                        </p>
                    </div>
                    <div class="card card_sphere">
                        <Icon color="#ff8c00" icon="material-symbols:currency-exchange" height="44" />
                        <p class="card_sphere_title">
                            {{ $t("acquiring.sphere.card3") }}
                        </p>
                    </div>
                    <div class="card card_sphere">
                        <Icon color="#ff8c00" icon="material-symbols:casino-outline" height="44" />
                        <p class="card_sphere_title">
                            {{ $t("acquiring.sphere.card4") }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="section currency">
                <div class="card currency_text">
                    <h5 v-html="$t('acquiring.currency.title')" class="main_sub_title" />
                    <p v-html="$t('acquiring.currency.desc')" />
                </div>
            </div>
            <div class="card section form">
                <h5 class="form_title">
                    {{ $t("acquiring.form.title") }}
                </h5>

                <div class="grid">
                    <div class="cell cell--6-col cell--4-col-tablet cell--4-col-phone form_field">
                        <MyInput v-model="name" :label="$t('acquiring.form.field_name')" :validation="validationName" />
                    </div>
                    <div class="cell cell--6-col cell--4-col-tablet cell--4-col-phone form_field">
                        <MyInput v-model="email" inputType="email" label="Email" :validation="validationEmail" />
                    </div>
                    <div class="cell cell--6-col cell--4-col-tablet cell--4-col-phone form_field">
                        <MyInput v-model="url" :label="$t('acquiring.form.field_url')" :validation="validationUrl" />
                    </div>
                    <div class="cell cell--6-col cell--4-col-tablet cell--4-col-phone form_field">
                        <MyInput inputType="number" type="number" v-model="turnover" :label="$t('acquiring.form.field_turnover')" :validation="validationTurnover" />
                    </div>
                </div>
                <div ref="form" class="form_btn_wrap">
                    <MyButton class="form_btn" :spinner="spinner" :value="$t('acquiring.form.submit_btn')" @click.native="onSubmit" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MyButton from "../components/ui/button.vue";
import MyInput from "../components/ui/input.vue";

// Icons
import { Icon } from "@iconify/vue2";

// Services
import OrderAcquiringService from "../services/OrderAcquiring";

// Store
import {mapMutations} from 'vuex'

export default {
    components: {
        MyButton,
        Icon,
        MyInput
    },
    data() {
        return {
            name: "",
            email: "",
            url: "",
            turnover: "",
            validationName: { isValid: true, text: "" },
            validationEmail: { isValid: true, text: "" },
            validationUrl: { isValid: true, text: "" },
            validationTurnover: { isValid: true, text: "" },
            spinner: false
        };
    },
    watch: {
        name() {
            this.validationName = {
                isValid: true,
                text: ""
            };
        },
        email() {
            this.validationEmail = {
                isValid: true,
                text: ""
            };
        },
        url() {
            this.validationUrl = {
                isValid: true,
                text: ""
            };
        },
        turnover() {
            this.validationTurnover = {
                isValid: true,
                text: ""
            };
        }
    },
    methods: {
        ...mapMutations(['SET_SNACK_BAR']),
        scrollToForm() {
            this.$refs.form.scrollIntoView({
                behavior: "smooth"
            });
        },
        inputTurnover(sum) {
            let a = +sum;

            if (!a) {
                a = 0;
            }

            this.turnover = a;
        },
        validateEmail(email) {
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        onSubmit() {
            if (this.spinner) return;

            let errorText = this.$t("acquiring.submit_handler.field_empty");

            let email = this.email.toLowerCase();

            if (!this.name) {
                this.validationName = {
                    isValid: false,
                    text: errorText
                };
            } else if (!email) {
                this.validationEmail = {
                    isValid: false,
                    text: errorText
                };
            } else if (!this.url) {
                this.validationTurnover = {
                    isValid: false,
                    text: errorText
                };
            } else if (!+this.turnover) {
                this.validationTurnover = {
                    isValid: false,
                    text: errorText
                };
            } else if (!this.validateEmail(email)) {
                this.validationEmail = {
                    isValid: false,
                    text: this.$t("acquiring.submit_handler.incorrect_email")
                };
            } else {
                let data = {
                    email,
                    name: this.name,
                    turnover: this.turnover,
                    url: this.url
                };

                this.spinner = true;

                let successMessage = this.$t("acquiring.submit_handler.success_message");

                OrderAcquiringService.create(data)
                    .then(() => {
                        // this.$toast.info(successMessage, {
                        //     position: "bottom-center",
                        //     icon: false
                        // });
                        this.SET_SNACK_BAR({ active: true, text: successMessage });
                        this.name = "";
                        this.email = "";
                        this.url = "";
                        this.turnover = "";
                    })
                    .catch(e => {
                        let errorMessage = e.response?.data?.message;
                        this.SET_SNACK_BAR({ active: true, text: errorMessage });
                        // this.$toast.error(errorMessage, {
                        //     position: "bottom-center",
                        //     icon: false
                        // });
                    })
                    .finally(() => {
                        this.spinner = false;
                    });
            }

            // email lower case
        }
    }
};
</script>
<style lang="scss" scoped>
.acquaring_page {
    color: $black;
    font-size: 16px;
    margin-top: 32px;
}

.acquaring_page .content {
    width: 80%;
    margin: 0px auto;
}

.acquaring_page .card {
    background-color: $white;
    border-radius: $border-sm;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
    padding: 24px 24px;
}

.top_self {
    align-self: top;
}
.bottom_self {
    align-self: end;
}

.acquaring_page .top_content_btn {
    margin-top: 24px;
}

.acquaring_page .card_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 12vw;
    height: 12vw;
    padding: 14px;
}

.acquaring_page .card_info_title {
    text-align: center;
    font-size: 16px;
    color: $grey-lg-text;
    line-height: 22px;
    margin-top: 16px;
}

.acquaring_page .section {
    margin: 32px 0px;
}

.acquaring_page .main_title {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 24px;
    display: flex;
    color: $grafit;
}

.acquaring_page .main_sub_title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 18px;
    color: $grafit;
}
.acquaring_page .card_info {
}

.acquaring_page .top_content {
    padding-top: 42px;
    padding-bottom: 42px;
    background-image: url("../assets/a1.png");
    background-repeat: no-repeat;
    background-position: 88% -40%;
    background-size: 33%;
}

.acquaring_page .top_content .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    display: flex;
    color: $grafit;
}

.acquaring_page .top_content .desc {
    font-size: 16px;
    color: $grey-lg-text;
    line-height: 22px;
    // margin-bottom: 16px;
}

.acquaring_page .features_cards {
    display: flex;
    justify-content: space-between;
    height: 15vw;
    // flex-wrap: wrap;
}

.acquaring_page .sphere_cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.acquaring_page .card_sphere {
    margin: 0;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.acquaring_page .card_sphere:nth-child(odd) {
    width: calc(50% - 8px);
}

.acquaring_page .card_sphere:nth-child(even) {
    width: calc(50% - 8px);
    // width: calc(50% - 24px);
}

.acquaring_page .card_sphere_title {
    font-size: 18px;
    color: $grey-lg-text;
    line-height: 22px;
    margin-left: 16px;
    font-weight: 500;
}

.acquaring_page .currency_text {
    font-size: 16px;
    color: $grey-lg-text;
    line-height: 22px;
    background-image: url("../assets/a2.png");
    background-repeat: no-repeat;
    //   background-attachment: fixed;
    background-position: 85% 23%;
    background-size: 34%;
}

.acquaring_page .form_title {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    color: $grafit;
}

.acquaring_page .form_btn_wrap {
    display: flex;
    justify-content: center;
}
.acquaring_page .form_btn {
    padding: 14px 40px;
}

.acquaring_page .form_field {
    min-height: 54px;
    box-sizing: border-box;
}

@media (max-width: 1000px) {
    .acquaring_page .top_content {
        background-position: 97%;
        background-size: 40%;
    }

    .acquaring_page .currency_text {
        background-position: 116% 0%;
        background-size: 50%;
    }

    .acquaring_page .features_cards {
        height: auto;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .acquaring_page .card_info {
        width: 160px;
        height: 160px;
        margin-bottom: 35px;
        // width: 24vw;
        // height: 24vw;
    }
    .acquaring_page .card_sphere {
        width: 100% !important;
    }

    .acquaring_page .form_btn {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .acquaring_page .content {
        width: auto;
        box-sizing: border-box;
        margin: 8px;
        padding: 24px 16px;
        margin-top: 0px;
        padding-top: 0px;
    }

    .acquaring_page .top_content {
        background-position: 122% 135%;
        background-size: 50%;
    }

    .acquaring_page .features_cards {
        display: flex;
        flex-wrap: wrap;
    }

    .acquaring_page .features_cards .card_info {
        width: calc(50% - 10px);
        margin: 5px;
        box-sizing: border-box;
    }
    .acquaring_page .currency_text {
        background-position: 130% -30%;
        background-size: 50%;
    }
}
</style>
