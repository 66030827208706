import Vue from "vue";
import Vuex from "vuex";

import content from "./modules/content";
import profile from "./modules/profile";
import technicalBreak from "./modules/break";
import order from "./modules/orders";
import ui from "./modules/ui";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        ui,
        technicalBreak,
        profile,
        content,
        order
    },
    strict: debug
});
