export default function initSelectedGive() {

    let currencies = this.currenciesGive;
    let selected = this.selected.give;

    if (this.pairLink && this.pairLink.exc) {
        this.selected.give = this.pairLink.exc.give;
        this.pairLink = null;
    } else if (!this.arrayEmpty(currencies) && !selected) {
        this.selected.give = currencies[0];
    } else if (!this.arrayEmpty(currencies) && selected && currencies.findIndex(c => c._id === selected._id) === -1) {
        this.selected.give = currencies[0];
    }
}
