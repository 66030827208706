export default function(exc) {
    let validationTakeFields = {};
    let validationGiveFields = {};
    let takeFields = exc.currency.take.fields.take;
    let giveFields = exc.currency.give.fields.give;

    takeFields.forEach(field => {
        validationTakeFields[field._id] = {
            isValid: true,
            text: ""
        };
    });

    giveFields.forEach(field => {
        validationGiveFields[field._id] = {
            isValid: true,
            text: ""
        };
    });
    this.validationTakeFields = validationTakeFields;
    this.validationGiveFields = validationGiveFields;
}
