<template lang="">
    <div class="processStepSection">
        <SyncLoader :loading="true"  color="#ff8c00" height="24px" width="24px"/>
    </div>
</template>
<script>
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
export default {
    components: {
        SyncLoader
    }
};
</script>
<style lang="scss" scoped>
.processStepSection {
    padding: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
