<template lang="">
    <div class="orderHeaderSection">
        <div>
            <div class="textLine">
                <h4 class="orderId">№ {{ " " }}{{ id }}</h4>
                <CopyBoard :text="id + ''" />
            </div>
            <div class="textLine orderLinkSection">
                <h4 class="orderLink">
                    {{ $t("orderPage.header.orderLink") }}
                </h4>
                <CopyBoard :text="orderLink + ''" />
            </div>
        </div>
        <div class="orderHeaderSection_timeSection">
            <h5 v-if="time" class="orderHeaderSection_timer">
                {{ time }}
            </h5>
            <h5 class="orderHeaderSection_dateCreated">
                {{ moment(created).format("HH:mm DD.MM.YYYY") }}
            </h5>
        </div>
    </div>
</template>
<script>
// Components
import CopyBoard from "../../components/ui/CopyBoard.vue";

// Config
import { URL } from "../../config";

// Time
import moment from "moment";

export default {
    props: {
        id: Number,
        hash: String,
        created: String,
        finished: String,
        statusPayment: Boolean,
        sendExpireDate: String || undefined,
        statusConfirmReceive: Boolean
    },
    data() {
        return {
            now: new Date(),
            timer: ""
        };
    },
    components: {
        CopyBoard
    },
    methods: {
        moment
    },
    created() {
        this.timer = setInterval(() => {
            this.now = new Date();
        }, 1000);
    },
    computed: {
        orderLink() {
            return URL.client + "order/" + this.hash;
        },
        timeToFinish() {
            return new Date(this.finished) - this.now;
        },
        time() {
            if (this.statusPayment) {
                let diff = new Date(this.finished) - this.now;
                let min = Math.floor(diff / 60000);
                let sec = Math.floor((diff % 60000) / 1000);
                return `${min}:${sec}`;
            } else if (this.statusConfirmReceive) {
                let diff = new Date(this.sendExpireDate) - this.now;
                let min = Math.floor(diff / 60000);
                let sec = Math.floor((diff % 60000) / 1000);
                if (min < 0 || sec < 0) {
                    return "";
                } else {
                    return `${min}:${sec}`;
                }
            } else {
                return undefined;
            }
        }
    },
    destroyed() {
        clearInterval(this.timer);
    }
};
</script>
<style lang="scss" scoped>
.orderHeaderSection {
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
}

.orderHeaderSection .textLine {
    display: flex;
    align-items: center;
}
.orderHeaderSection .orderId {
    font-size: 22px;
    font-weight: 600;
    margin-right: 8px;
}
.orderHeaderSection .orderLink {
    font-size: 14px;
    margin-right: 8px;
    color: #ff8c00;
}

.orderLinkSection {
    margin-top: 8px;
}

.orderHeaderSection_timeSection {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: flex-end;
}

.orderHeaderSection_timer {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.orderHeaderSection_dateCreated {
    font-size: 14px;
}
</style>
