<template>
    <transition-group class="grid" name="orders" tag="div">
        <item-order v-for="item in orders" :key="item._id" :order="item" @click.native.stop="e => clickItem(item)"></item-order>
    </transition-group>
</template>

<script>
import { mapMutations } from "vuex";
import itemOrder from "../../components/item/order";
import OrderService from "../../services/OrderService";

export default {
    data() {
        return {
            orders: []
        };
    },
    components: {
        itemOrder
    },
    mounted() {
        this.fetchOrders();
        this.sockets.subscribe("update order", order => {
            this.orders = this.orders.map(item => {
                if (item._id === order._id) {
                    return Object.assign({}, item, order);
                } else {
                    return item;
                }
            });
        });
        this.sockets.subscribe("add order", order => {
            this.orders = [order, ...this.orders];
        });
    },
    beforeDestroy() {
        this.orders = [];
        this.sockets.unsubscribe("add order");
        this.sockets.unsubscribe("update order");
    },
    methods: {
        ...mapMutations(["SET_CURRENT_ORDER", "SET_LOADING"]),
        clickItem(i) {
            let { href } = this.$router.resolve("/order/" + i.hash);
            window.open(href, "_blank");
        },
        async fetchOrders() {
            try {
                let response = await OrderService.getAll();
                this.orders = response.data;
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>

<style></style>
