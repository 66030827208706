<template>
    <div>
        <div class="profile-view referral" v-if="user.serviceStatus !== 'permanent'">
            <div class="view-name" :class="'referral'">
                {{ $t("myCardsPage.title") }}
            </div>

            <div class="access-denied">
                <span v-if="hasCards" class="user-name">{{ userName }},</span>
                <span v-if="hasCards"> {{ $t("myCardsPage.hasCardsText") }}</span>

                <span v-if="!hasCards" class="user-name">{{ userName }},</span>
                <span v-if="!hasCards">
                    {{ $t("myCardsPage.notHasCardsText") }}
                </span>
                <div class="spacer"></div>
                <router-link :to="'/'" class="button linkButton">
                    {{ $t("myCardsPage.createOrderLink") }}
                </router-link>
            </div>
        </div>

        <transition-group class="grid" name="cards" tag="div">
            <div @click="openAddCard" class="cell cell--4-col cell--4-col-tablet cell--4-col-phone add-card" v-if="user.serviceStatus == 'permanent'" key="addCard">
                <div class="title">
                    {{ $t("myCardsPage.addCardBtn") }}
                </div>
                <div class="icon" :title="$t('myCardsPage.addCardBtn')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="-2 -1 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square">
                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                        <line x1="12" y1="8" x2="12" y2="16"></line>
                        <line x1="8" y1="12" x2="16" y2="12"></line>
                    </svg>
                </div>
            </div>

            <item-card v-for="item in cards" :key="item._id" :card="item" @click.native.stop="card = item"></item-card>
        </transition-group>
        <CardModal :show="showModal" :closeModal="closeShowModal" :cardData="card" />

        <!-- <VueModal :style="'card-panel-style'" :defaultWidth="'fit-content'" :bg-overlay="'#00000052'" :bgPanel="'white'" :visible="showModal" @hideModal="hideCard" :animation-panel="'modal-slide-bottom'"> <card :setCard="setCard" :card="card" :hideHandler="hideCard" /> </VueModal> -->
    </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import itemCard from "../../components/item/card";
// import card from "../../components/popup/card/card";
// import VueModal from "../../components/vue-modal/vue-modaltor.vue";
import CardService from "../../services/CardService";

import CardModal from "../../components/Card/CardModal.vue";


export default {
    components: {
        // VueModal,
        itemCard,
        // card,
        CardModal
    },
    data() {
        return {
            cards: [],
            transition: null,
            card: null,
            showModal: false
        };
    },
    computed: {
        ...mapGetters(["user"]),
        userName() {
            return this.user.name || this.user.email;
        },
        hasCards() {
            return this.cards.length !== 0;
        }
    },
    mounted() {
        this.fetchCards();
        this.sockets.subscribe("update card", card => {
            if (this.card && card._id == this.card._id) {
                this.card = card;
            }
            this.cards = this.cards.map(item => {
                if (item._id === card._id) {
                    return Object.assign({}, item, card);
                } else {
                    return item;
                }
            });
        });
        this.sockets.subscribe("add card", card => {
            this.cards = [card, ...this.cards];
        });
    },
    beforeDestroy() {
        this.cards = [];
        this.sockets.unsubscribe("add card");
        this.sockets.unsubscribe("update card");
    },
    watch: {
        card() {
            let card = this.card;
            if (card) {
                this.showModal = true;
            }
        },
        showModal() {
            if (!this.showModal) {
                this.card = null;
            }
        }
    },
    methods: {
        ...mapMutations(["SET_LOADING"]),
        openAddCard() {
            this.card = {};
            this.showModal = true;
        },
        closeShowModal() {
            this.showModal = false;
            this.card = null;
        },
        async fetchCards() {
            try {
                let response = await CardService.getAll();
                this.cards = response.data;
            } catch (e) {
                console.error(e);
            }
        },
        setCard(card) {
            this.card = card;
        },
        hideCard() {
            this.showModal = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.profile {
    display: flex;
    flex-direction: column;
}

.profile > .grid {
    width: 100%;
}

.profile-view {
    background-color: $white;
    margin: 16px;
    padding: 16px 8px;
    border-radius: $border-sm;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.profile-view .view-name {
    color: $grafit;
    font-size: 26px;
    margin: 0 16px 24px 16px;
}

.profile-view .view-name.referral {
    margin: 0 16px 8px 16px;
}

.cards-view {
    margin-top: 1rem;
    padding: 0 8px;
    border-radius: $border-sm;
    background-color: #fff;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.cards-view .view-name {
    color: $grafit;
    font-size: 26px;
    margin: 0 16px 24px 16px;
}

.orders-move,
.cards-move {
    transition: transform 0.64s;
}

.add-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: 16px;
    border-radius: $border-sm;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.32);
}

.add-card .title {
    font-size: 18px;
    color: $grafit;
    margin-right: 16px;
}

.add-card .icon {
    cursor: pointer;
    color: $grafit;
}

.add-card .icon:hover {
    color: $grey-lg-text;
}

.referral > .grid {
    width: 100%;
}

.referral > .grid > .cell {
    padding: 0;
}

.referral .description p {
    color: $grafit;
    font-size: 14px;
    line-height: 18px;
}

.referral .description p:not(:last-child) {
    margin-bottom: 16px;
}

.referral .referral-link-card,
.referral .promo-code-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px !important;
    border: 1px solid #ebeff6;
    border-radius: $border-sm;
}

.referral .referral-link-card > .title,
.referral .promo-code-wrap > .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
}

.referral .referral-link-card > .link {
    color: $black;
    font-size: 16px;
    font-weight: 400;
    background-color: #ebeff6;
    padding: 12px 16px;
    margin-bottom: 16px;
    border-radius: $border-sm;
}

.referral > .border {
    width: calc(100% - 32px);
    margin: 24px 16px;
}

.referral .referral-accruals > .title,
.referral .cash-back-accruals > .title {
    color: $grafit;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 32px;
}

.referral .referral-details,
.referral .cash-back-details {
    background-color: #ebeff6;
    padding: 12px 16px !important;
    border-radius: $border-sm;
}

hr {
    opacity: 0.5;
}

.referral .referral-details > .description,
.referral .cash-back-details > .description {
    color: $grafit;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
}

.referral .referral-details > .title,
.referral .cash-back-details > .title,
.referral .referral-details > .total,
.referral .cash-back-details > .total {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
}

.referral .referral-details > .total > .code,
.referral .cash-back-details > .total > .code {
    font-weight: 400;
}

.referral .referral-details > .history,
.referral .cash-back-details > .history {
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
}

.referral .promo-code-wrap {
    margin-top: 32px;
}

.referral .promo-code-wrap > .title {
    margin-bottom: 0;
}

.referral .access-denied {
    display: flex;
    color: $grafit;
    font-size: 16px;
    padding: 0 16px;
    flex-wrap: wrap;
    align-items: center;
}

.referral .access-denied a {
    color: #fff;
}

.linkButton {
    margin: 1rem 0;
}

.referral .access-denied .user-name {
    margin-right: 0.5rem;
    font-weight: 500;
    font-size: 18px;
}

.settings .section-name {
    color: $grafit;
    font-size: 20px;
    margin: 16px;
}

.settings > .grid {
    width: 100%;
}

.settings > .grid > .cell {
    padding: 0 1%;
}

.settings > .grid > .cell:first-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-self: flex-start;
}

.settings > .grid > .cell:first-child > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
}

.settings .action {
    display: flex;
    flex-direction: row;
    margin: 24px 16px 16px 16px;
}

.email {
    color: $grafit;
    font-size: 16px;
}

.сhange-pass {
    box-shadow: -2px 0 2px -2px rgba(0, 0, 0, 0.16);
}

@media (max-width: 739px) {
    .сhange-pass {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.16);
    }
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .card {
    position: relative;
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
}

@media (max-width: 739px) {
    .container .card {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }
}

.container .card:hover {
    transform: translateY(5px);
}

.container .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.03);
    pointer-events: none;
    z-index: 1;
}

.circle svg {
    position: relative;
    width: 150px;
    height: 150px;
    z-index: 1000;
}

svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: $grey-lg-text;
    stroke-width: 10;
    stroke-linecap: round;
    transform: translate(5px, 5px);
}

svg circle:nth-child(2) {
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
}

.card:nth-child(1) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440px - (440px * 100) / 100);
    stroke: $orange;
}

.card:nth-child(2) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: $green;
}

.card:nth-child(3) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: $blue;
}

.percent {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: inset 0 0 50px $page-wrap;
    z-index: 1;
    margin: 0 auto;
}

.percent .number {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.percent .number h2 {
    color: $grafit;
    font-weight: 600;
    font-size: 26px;
    transition: 0.5s;
}

.card:hover .percent .number h2 {
    color: $grafit;
    font-size: 30px;
}

.card:hover .percent img {
    width: 100px;
    transition: 0.5s;
}

.percent .number h2 span {
    font-size: 24px;
    color: $grafit;
}

.card:hover .percent .number h2 span {
    font-size: 24px;
    color: $grafit;
    transition: 0.5s;
}

.card:hover .percent .number h3 span {
    font-size: 24px;
    color: $grafit;
    transition: 0.5s;
}

.text {
    position: relative;
    color: $grey-text;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1px;
    transition: 0.5s;
    max-width: 200px;
}

.card:hover .text {
    color: $grey-text;
}

.description-setting {
    background-color: #ebeff6;
    padding: 2px 16px !important;
    border-radius: $border-sm;
    text-align: start !important;
    line-height: 25px;
    margin-top: 1rem;
    display: block !important;
}

.description-setting div {
    margin: 10px 0;
}

.description-setting b {
    font-weight: 500;
}

.deleteAccBtn {
    margin-top: 2rem;
}

.deleteAccBtn {
    background-color: $red !important;
}

.deleteAccBtn:hover {
    background-color: $red !important;
}

.level-image {
    width: 90px;
    margin-top: 1rem;
    transition: 0.5s;
}
</style>
