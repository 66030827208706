import currenciesTake from "./currenciesTake";
import allCurrenciesGive from "./allCurrenciesGive";
import giveFilterList from "./giveFilterList";
import currenciesGive from "./currenciesGive";
import image from "./image";
import rateHTML from "./rateHTML";
import precision from "./precision";
import getCity from "./getCity";
import limit from "./limit";
import getCommissionQiwi from "./getCommissionQiwi";
import promocodeDiscount from "./promocodeDiscount";
import getPromocodeGiveAmount from "./getPromocodeGiveAmount";
import getPromocodeTextInfo from "./getPromocodeTextInfo";
import takeCurrencyMultinet from "./takeCurrencyMultinet";
import giveCurrencyMultinet from "./giveCurrencyMultinet";


export default {
    takeCurrencyMultinet,
    giveCurrencyMultinet,
    
    getPromocodeGiveAmount, getPromocodeTextInfo, getCommissionQiwi, promocodeDiscount, limit, rateHTML, getCity, precision, currenciesTake, image, allCurrenciesGive, giveFilterList, currenciesGive };
