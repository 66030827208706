import axios from "axios";

class OrderService {
    getOneByHash(hash) {
        return axios.get("/orders/" + hash);
    }
    getAll() {
        return axios.get("profile/orders");
    }
    create(data) {
        return axios.post("orders", data);
    }
    change(data) {
        return axios.put("profile/orders", data);
        // if (auth) {
        //     return axios.put("profile/orders", data);
        // } else {
        //     let orderToken = localStorage.getItem("orderToken");

        //     if (orderToken) {
        //         return axios.put("profile/changeOrderUnauth", data, {
        //             headers: {
        //                 orderToken: orderToken
        //             }
        //         });
        //     } else {
        //         return null;
        //     }
        // }
    }
    changeAmount(data) {
        return axios.put("profile/orders/amount", data);
        // if (auth) {
        //     return axios.put("profile/orders/amount", data);
        // } else {
        //     let orderToken = localStorage.getItem("orderToken");

        //     if (orderToken) {
        //         return axios.put("profile/changeAmountUnauth", data, {
        //             headers: {
        //                 orderToken: orderToken
        //             }
        //         });
        //     } else {
        //         return null;
        //     }
        // }
    }
    changeDirection() {
        // if (auth) {
        //     return axios.put("profile/orders/direction", data);
        // } else {
        //     let orderToken = localStorage.getItem("orderToken");
        //     if (orderToken) {
        //         return axios.put("profile/newDirectionUnauth", data, {
        //             headers: {
        //                 orderToken: orderToken
        //             }
        //         });
        //     } else {
        //         return null;
        //     }
        // }

        // try {
        //     if (auth) {
        //         await axios
        //             .put("profile/orders/direction", data)
        //             .then(response => {
        //                 let order = response.data;
        //                 commit("SET_CURRENT_ORDER", order);
        //                 handler();
        //             })
        //             .catch(err => {
        //                 handler();
        //                 console.error(err);
        //             });
        //     } else {
        //         let orderToken = localStorage.getItem("orderToken");
        //         if (orderToken) {
        //             await axios
        //                 .put("profile/newDirectionUnauth", data, {
        //                     headers: {
        //                         orderToken: orderToken
        //                     }
        //                 })
        //                 .then(response => {
        //                     let order = response.data;
        //                     commit("SET_CURRENT_ORDER", order);
        //                     handler();
        //                 })
        //                 .catch(err => {
        //                     handler();
        //                     console.error(err);
        //                 });
        //         }
        //     }
        // } catch (e) {
        //     console.error(e);
        // }
    }
    getUnauthOrder() {
        let orderToken = localStorage.getItem("orderToken");
        if (orderToken) {
            return axios.get("profile/activeOrder", {
                headers: {
                    orderToken: orderToken
                }
            });
        }
    }
}

const orderService = new OrderService();

export default orderService;
