const state = {
    isDesktop: false,
    referralLink: null,
    isLoading: false,
    image: null,
    snackBar: {
        active: false,
        text: ""
    }
};

const actions = {
    setScreen({ commit }, screen) {
        commit("SET_SCREEN", screen);
    },
    setReferralLink({ commit }, link) {
        commit("SET_REFERRAL_LINK", link);
    },
    setLoading({ commit }, isLoading) {
        commit("SET_LOADING", isLoading);
    },
    setImage({ commit }, image) {
        commit("SET_IMAGE", image);
    },
    setSnackBar({ commit }, { active, text }) {
        commit("SET_SNACK_BAR", { active, text });
    }
};

const mutations = {
    SET_SCREEN(state, screen) {
        state.isDesktop = screen;
    },
    SET_REFERRAL_LINK(state, referralLink) {
        state.referralLink = referralLink;
    },
    SET_LOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    SET_IMAGE(state, image) {
        state.image = image;
    },
    SET_SNACK_BAR(state, { active, text }) {
        state.snackBar.active = active;
        state.snackBar.text = text;
    },
    SET_DIALOG_CARD(state, dialog) {
        state.dialogCard = dialog;
    },
    SET_DIALOG_ORDER(state, dialog) {
        state.dialogOrder = dialog;
    }
};

const getters = {
    isDesktop(state) {
        return state.isDesktop;
    },
    referralLink(state) {
        return state.referralLink;
    },
    isLoading(state) {
        return state.isLoading;
    },
    image(state) {
        return state.image;
    },
    snackBar(state) {
        return state.snackBar;
    },
    dialogCard(state) {
        return state.dialogCard;
    },
    dialogOrder(state) {
        return state.dialogOrder;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
