export default function getCommissionBTC(amountTake, amountGive, action) {
    const exchange = this.exchange;

    let than = this;

    const conversionInRub = (amount, type, withFormula = false) => {
        let formula = exchange.rate.formula;

        if (type === "take") {
            let result;
            const takeCode = exchange.currency.take.code;
            const takeValue = exchange.currency.take.evalue;

            if (takeCode === "RUB") {
                result = Math.floor(+amount * this.precision.take) / this.precision.take;
            } else if (takeCode === "EUR" || takeCode === "USD") {
                result = Math.floor(+amount * takeValue * this.precision.take) / this.precision.take;
            } else {
                result = Math.floor(+amount * takeValue * this.precision.take) / this.precision.take;
            }

            if (withFormula) {
                result = Math.floor(+result * formula * this.precision.take) / this.precision.take;
            }

            return result;
        } else {
            const giveValue = exchange.currency.give.evalue;
            let result = Math.floor(giveValue * +amount * this.precision.give) / this.precision.give;
            if (withFormula) {
                result = this.getExchangeAmount(exchange, "take", amount, true);
            }
            return result;
        }
    };

    const resetCommissionInfo = () => {
        this.commissionInfo = "";
    };

    const setCommissionInfo = range => {
        let commission = range.sum;
        let oneTake = conversionInRub(1, "take");
        let fromSum = Math.floor((range.from / oneTake) * this.precision.take) / this.precision.take;
        let toSum = Math.floor((range.to / oneTake) * this.precision.take) / this.precision.take;
        let code = exchange.currency.take.code;
        let minTake = this.limit.min;
        if (toSum > minTake) {
            this.commissionInfo = than.$t("exchanger.getCommissionBTC.commissionInfo", { fromSum, toSum, code, commission });

            // `При обмене от <b style="font-weight: 500">${fromSum} ${code}</b> до <b style="font-weight: 500">${toSum} ${code}</b> взымается доп. комиссия ${commission} BTC.`;
            return commission;
        } else {
            return 0;
        }
    };

    if (this.exchange.currency.give.code !== "BTC") {
        resetCommissionInfo();
        return 0;
    }

    if (!exchange.currency.take.BTCFeeEnabled) {
        this.commissionInfo = "";
        this.minExc = exchange.limit.min;
        return 0;
    }

    const ranges = this.exchange.currency.give.commissionBTC;

    if (action === "take") {
        const takeRub = conversionInRub(amountTake, "take");
        // console.log(takeRub, "take in rub");

        let firstRange = ranges[0];
        if (firstRange) {
            let oneTake = conversionInRub(1, "take");
            let fromSum = Math.floor((firstRange.from / oneTake) * this.precision.take) / this.precision.take;
            // console.log(fromSum, "from sum");
            let minTake = exchange.limit.min;
            if (fromSum < minTake) {
                this.minExc = fromSum;
            } else {
                this.minExc = null;
            }
        } else {
            this.minExc = null;
        }
        let findRange = ranges.find(r => takeRub >= r.from && r.to >= takeRub);
        // console.log(findRange, "findRange");
        if (findRange) {
            return setCommissionInfo(findRange);
        } else {
            resetCommissionInfo();
            return 0;
        }
    } else {
        const giveRub = conversionInRub(amountGive, "give", true);

        let firstRange = ranges[0];
        if (firstRange) {
            let oneTake = conversionInRub(1, "take");
            let fromSum = Math.floor((firstRange.from / oneTake) * this.precision.take) / this.precision.take;
            console.log(fromSum, "from sum");
            let minTake = exchange.limit.min;
            if (fromSum < minTake) {
                this.minExc = fromSum;
            } else {
                this.minExc = null;
            }
        } else {
            this.minExc = null;
        }
        // console.log(amountTake, "amountTake");
        // console.log(giveRub, "giveRub");
        let findRange = ranges.find(r => giveRub >= r.from && r.to >= giveRub);
        // console.log(findRange, "findRange");
        if (findRange) {
            return setCommissionInfo(findRange);
        } else {
            resetCommissionInfo();
            return 0;
        }
    }
}
