export default function rateUpdate() {

    // let commissionQiwi = this.getCommissionQiwi;
    // let promocodeDiscount = this.promocodeDiscount;

    if (!this.exchange) {
        this.amount = {
            take: 0,
            give: 0
        };
        return;
    }

    if (this.lastInputActive === "take") {
        this.calculateGive(+this.amount.take);
        // let give = this.getExchangeAmount(this.currentExchange, "give", +this.amount.take);

        // const commissionBTC = this.getCommissionBTC(this.amount.take, give, "take");

        // if (commissionBTC) {
        //     const calcAmountGive = Math.floor((give - commissionBTC) * this.precision.give) / this.precision.give;
        //     if (calcAmountGive > 0) {
        //         give = calcAmountGive;
        //     } else {
        //         give = 0;
        //     }
        // } else if (commissionQiwi) {
        //     const subGive = this.amount.give * commissionQiwi;
        //     const calcAmountGive = Math.floor((give - subGive) * this.precision.give) / this.precision.give;
        //     if (calcAmountGive > 0) {
        //         give = calcAmountGive;
        //     } else {
        //         give = 0;
        //     }
        // }

        // if (promocodeDiscount) {
        //     let addSum = promocodeDiscount * give;
        //     give = Math.floor((give + addSum) * this.precision.give) / this.precision.give;

        //     if (give < 0) {
        //         give = 0;
        //     }
        // }

        // this.amount.give = give;
    } else {
        this.calculateTake(+this.amount.give);

        // let amountGive = +this.amount.give;
        // if (promocodeDiscount) {
        //     let subSum = promocodeDiscount * amountGive;
        //     amountGive -= subSum;
        //     if (amountGive < 0) {
        //         amountGive = 0;
        //     }
        // }

        // let take = this.getExchangeAmount(this.currentExchange, "take", amountGive);

        // const commissionBTC = this.getCommissionBTC(take, amountGive, "give");

        // if (commissionBTC) {
        //     const calcAmountTake = this.getExchangeAmount(this.currentExchange, "take", +amountGive + commissionBTC);

        //     if (calcAmountTake > 0) {
        //         take = calcAmountTake;
        //     } else {
        //         take = 0;
        //     }
        // } else if (commissionQiwi) {
        //     const addGive = this.amount.give * commissionQiwi;
        //     const calcAmountTake = this.getExchangeAmount(this.currentExchange, "take", amountGive + addGive);

        //     if (calcAmountTake > 0) {
        //         take = calcAmountTake;
        //     } else {
        //         take = 0;
        //     }
        // }
        // this.amount.take = take;
    }
}
