import Vue from "vue";
import VueI18n from "vue-i18n";
import ru from "./locales/ru.json";
import en from "./locales/en.json";

Vue.use(VueI18n);

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const langInit = urlParams.get("lang");

let locale = localStorage.getItem("locale") || "ru";

if (langInit) {
    locale = langInit == "ru" ? "ru" : "en";
}

const i18n = new VueI18n({
    locale,
    messages: {
        ru,
        en
    }
});

export { i18n };
