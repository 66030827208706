<template>
    <div class="backdrop" @click="close" @keyup.esc="close"></div>
</template>

<script>
export default {
    methods: {
        close() {
            this.$emit("close");
        }
    }
};
</script>

<style scoped>
.backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.32);
    transform: translateZ(0);
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}

.backdrop.active {
    opacity: 1;
    pointer-events: auto;
}

.backdrop.transparent {
    background: rgba(0, 0, 0, 0.005);
}
</style>
