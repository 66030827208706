export default function limit() {
    let exchange = this.exchange;

    if (exchange) {
        return {
            min: this.minExc ? this.minExc : exchange.limit.min,
            max: exchange.limit.max
        };
    }
}
