<template>
    <div class="resolvedSection">
        <div v-if="addressTransaction" class="textLine">
            <h6 class="wishText">
                {{ $t("orderPage.resolved.trans") }}
                <a target="_blank" :href="addressTransaction" class="link">
                    <span>{{ $t("orderPage.resolved.transLink") }}</span> <Icon color="#ff8c00" icon="akar-icons:link-out" height="16"
                /></a>
            </h6>
        </div>

        <div v-if="!!order.sendQrInfo" class="textLine">
            <h6 class="qr">{{ order.sendQrInfo }}</h6>
        </div>

        <div class="textLine">
            <h6 class="wishText">
                {{ $t("orderPage.resolved.thank") }}

                <a target="_blank" :href="reviewLink" class="link"><span>Bestchange</span> <Icon color="#ff8c00" icon="akar-icons:link-out" height="16"/></a>
            </h6>
        </div>

        <h6 class="thank">{{ $t('orderPage.resolved.t') }}</h6>
    </div>
</template>

<script>
import CONSTANT_LINKS from "../../../constants/links.json";
import { getFullBlockchainTransactionUrl } from "../../../utils/currency/getFullBlockchainTransactionUrl";

import { Icon } from "@iconify/vue2";

//  comp
export default {
    props: {
        order: Object
    },
    components: {
        Icon
    },
    computed: {
        addressTransaction() {
            let order = this.order;
            let linkTake = order.currency.give.currency.transactionLink;
            let addressPayment = order.fields.give.find(field => field.isAddress);
            return getFullBlockchainTransactionUrl(linkTake, addressPayment?.value);
        },
        reviewLink() {
            let locale = this.$i18n.locale === "ru" ? "rus" : "eng";
            let domain = CONSTANT_LINKS.bestchange[locale];
            return domain + "/crypik-exchanger.html";
        }
    }
};
</script>

<style lang="scss" scoped>
.resolvedSection {
    padding: 0px 16px 16px 16px;
    margin-top: 15px;
}

.resolvedSection .textLine {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.resolvedSection .link {
    color: #ff8c00;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.resolvedSection .link:hover {
    text-decoration: underline;
}

.resolvedSection .wishText {
    margin-right: 5px;
    display: flex;
}

.resolvedSection .thank {
    text-align: center;
}

.resolvedSection .qr {
    text-align: center;
    font-weight: 600;
}
</style>
