import Vue from "vue";
import App from "./app.vue";

import router from "./router";
import store from "./store";
import axios from "axios";

import Extensions from "../plugins/extensions";
import FastClick from "fastclick";
import SocketIO from "../plugins/socket-io";
import { mapGetters, mapMutations } from "vuex";
import eventer from "./event";
import { i18n } from "./i18n";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { URL } from "./config.js";

import "./assets/styles/styles.scss";

Vue.prototype.URL = URL;
Vue.prototype.eventer = eventer;

console.log("Version", 1.006);

// Подключение к сокету на клиенте:
Vue.use(
    new SocketIO({
        debug: false,
        connection: URL.server,
        reconnection: true,
        vuex: {
            store,
            actionPrefix: "SOCKET_"
        }
    })
);

Vue.use(Extensions);
Vue.use(Toast, {
    containerClassName: "my-toast-container"
});

axios.defaults.baseURL = URL.api;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["scode"] = "C";

axios.interceptors.response.use(
    res => {
        return res;
    },
    error => {
        if (error.response.status === 403) {
            store.commit("LOGOUT_USER");
            store.commit("SET_SNACK_BAR", { active: true, text: i18n.t("notify.authExpire") });
        }
        return Promise.reject(error);
    }
);

axios.interceptors.request.use(
    request => {
        request.headers.common["token"] = store.state.profile.token;
        let locale = i18n.locale == "ru" ? "rus" : "eng";
        request.headers.common["locale"] = locale;
        return request;
    },
    e => {
        return Promise.reject(e);
    }
);

FastClick.attach(document.body);

Vue.mixin({
    methods: {
        arrayEmpty: arr => !(Array.isArray(arr) && arr.length),
        objectEmpty: obj => Object.keys(obj).length === 0 && obj.constructor === Object
    }
});

Vue.directive("click-outside", {
    bind: (el, binding) => {
        const bubble = binding.modifiers.bubble;
        const handler = e => {
            if (bubble || (!el.contains(e.target) && el !== e.target)) {
                binding.value(e);
            }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener("click", handler);
    },
    unbind: el => {
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
    }
});

export default new Vue({
    el: "#app",
    router,
    i18n,
    store,
    render: h => h(App),
    computed: {
        ...mapGetters(["order", "orderToken"]),
        isDesktop() {
            return this.$store.state.ui.isDesktop;
        },
        isAuthenticated() {
            return this.$store.state.profile.isAuthenticated;
        },
        token() {
            return this.$store.state.profile.token;
        },
        referralLink() {
            return this.$route.query.ref;
        },
        currentOrder() {
            return this.order;
        }
    },

    watch: {
        isAuthenticated() {
            if (this.isAuthenticated) {
                this.socketAuthenticate();
                this.$store.dispatch("getUser");
                this.SET_ORDER_TOKEN(null);
            } else {
                this.$socket.emit("signout");
            }
        }
    },
    async created() {
        this.$store.dispatch("fetchContent");
        this.$store.dispatch("setReferralLink", this.referralLink);

        if (this.isAuthenticated) {
            this.$store.dispatch("getUser");
        }

        if (!this.isAuthenticated) {
            this.SET_SNACK_BAR({
                active: true,
                text: this.$t("cookieNotifyText")
            });
        }
        this.initSocketListeners();
    },
    mounted() {
        this.$nextTick(function() {
            window.addEventListener("resize", this.updateWindowWidth);
            this.updateWindowWidth();
        });
    },
    sockets: {
        connect() {
            if (this.isAuthenticated) {
                this.socketAuthenticate();
            }
        }
    },
    methods: {
        ...mapMutations(["SET_ORDER_TOKEN", "SET_SNACK_BAR"]),
        updateWindowWidth() {
            let windowWidth = document.documentElement.clientWidth;

            if (windowWidth >= 814 && this.isDesktop === false) {
                this.$store.dispatch("setScreen", true);
            }
            if (windowWidth < 814 && this.isDesktop === true) {
                this.$store.dispatch("setScreen", false);
            }
        },

        initSocketListeners() {
            this.sockets.subscribe("update user", user => {
                this.$store.commit("SET_USER", user);
            });

            this.sockets.subscribe("update order", order => {
                this.$store.commit("UPDATE_ORDER", order);
            });
        },
        socketAuthenticate() {
            this.$socket.emit("authenticate", this.$store.state.profile.token);
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateWindowWidth);
    }
});
