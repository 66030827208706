import axios from "axios";

const state = {
    isAuthenticated: !!localStorage.getItem("token"),
    user: null,

    token: localStorage.getItem("token")
};

const actions = {
    async userSignup({ commit }, { data, successHandler, errorHandler }) {
        try {
            await axios
                .post("signup", data)
                .then(response => {
                    commit("USER_LOGIN", response.data);
                    successHandler();
                })
                .catch(err => {
                    errorHandler(err.response.data);
                });
        } catch (e) {
            console.error(e);
        }
    },
    async userSignin({ commit }, { data, successHandler, errorHandler }) {
        try {
            await axios
                .post("signin", data)
                .then(response => {
                    commit("USER_LOGIN", response.data);
                    successHandler();
                })
                .catch(err => {
                    errorHandler(err.response.data);
                });
        } catch (e) {
            console.error(e);
        }
    },
    async getUser({ commit }) {
        try {
            await axios
                .get("profile")
                .then(response => {
                    commit("SET_USER", response.data);
                })
                .catch(() => {
                    commit("LOGOUT_USER");
                });
        } catch (e) {
            console.error(e);
        }
    },
    async userResetPassword(_, { email, successHandler, errorHandler }) {
        try {
            await axios
                .put("profile/resetPassword", { email })
                .then(successHandler)
                .catch(err => {
                    if (err.response.data.email) {
                        errorHandler(err.response.data.error);
                    }
                    console.error(err);
                });
        } catch (e) {
            console.error(e);
        }
    },
    async userConfirmResetPassword(_, { data, successHandler, errorHandler }) {
        try {
            await axios
                .put("profile/resetPassword", data)
                .then(successHandler)
                .catch(err => {
                    errorHandler(err.response.data.error);
                });
        } catch (e) {
            console.error(e);
        }
    },

    async userChange({ commit }, { data, successHandler, errorHandler }) {
        try {
            await axios
                .put("profile", data)
                .then(() => {
                    successHandler();
                    commit("SET_SNACK_BAR", { active: true, text: "Данные успешно изменены!" });
                })
                .catch(() => {
                    errorHandler();
                });
        } catch (e) {
            console.error(e);
        }
    },
    async userChangePassword({ commit }, { data, successHandler, errorHandler }) {
        try {
            await axios
                .put("profile/password", data)
                .then(() => {
                    successHandler();
                    commit("SET_SNACK_BAR", { active: true, text: "Пароль успешно изменён!" });
                })
                .catch(err => {
                    errorHandler(err.response.data);
                });
        } catch (e) {
            console.error(e);
        }
    },

    async deleteUser({ commit }) {
        try {
            await axios
                .put("profile/delete")
                .then(() => {
                    commit("LOGOUT_USER");
                })
                .catch(err => {
                    console.error(err.response.data.error);
                });
        } catch (e) {
            console.error(e);
        }
    }
};

const mutations = {
    USER_LOGIN(state, data) {
        let user = data.user;
        localStorage.setItem("token", data.token);
        state.token = data.token;
        state.user = user;
        state.isAuthenticated = true;
    },
    SET_USER(state, user) {
        let oldUser = state.user;
        let newUser = user;

        if (oldUser) {
            newUser.accrual = user.accrual && typeof user.accrual === "object" ? user.accrual : oldUser.accrual;
            newUser.referral = user.referral && typeof user.referral === "object" ? user.referral : oldUser.referral;
        }

        state.user = newUser;
    },
    LOGOUT_USER(state) {
        localStorage.removeItem("token");
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
    }
};

const getters = {
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    user(state) {
        return state.user;
    },
    token(state) {
        return state.token;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
