<template>
    <div class="exchange-agree-section">
        <p v-if="error" class="exchange-agree-section_error">
            {{ $t("agreeSelect.error") }}
        </p>
        <label class="checkbox">
            <p>
                {{ $t("agreeSelect.first") }} 
                {{ $t("agreeSelect.first") }} <router-link class="regulations" to="/agreement" tag="span"> {{ $t("agreeSelect.second") }} </router-link> {{ $t("agreeSelect.third") }} <router-link class="regulations" to="/aml-kyc" tag="span"> {{ $t("agreeSelect.fourth") }} </router-link>
            </p>
            <input type="checkbox" :checked="checked" @change="$emit('change', $event.target.checked)" />
            <span class="checkmark"></span>
        </label>
    </div>
</template>

<script>
export default {
    model: {
        prop: "checked",
        event: "change"
    },
    props: {
        checked: Boolean,
        error: Boolean
    }
};
</script>

<style lang="scss" scoped>

.exchange-agree-section {
    margin: 16px;
}

.exchange-agree-section_error {
    color: red;
    margin-bottom: 10px;
    font-size: 14px;
}
.checkbox {
    display: block;
    position: relative;
    padding-left: 36px;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $page-wrap;
    border-radius: 4px;
    border: 1px solid $code;
}

.checkbox input:checked ~ .checkmark {
    background-color: #f6c665;
}

.checkmark:after {
    content: "";
    position: absolute;
    border: 1px solid $black;
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 10px;
    //border: 1px solid #000000;
    border-width: 0 1.6px 1.6px 0;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    //transform: rotate(45deg);
}

.checkbox .regulations {
    color: $code;
    text-decoration: underline;
}

.checkbox .regulations:hover {
    color: #20799b;
}
</style>
