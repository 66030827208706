<template lang="">
    <div class="modalContentWrap">
        <div class="modalContentWrap_header">
            <MoonLoader v-if="showSpinner" class="modalContentWrap_spiner" color="#ff8c00" height="20px" width="20px" />
            <div class="modalContentWrap_headerName">{{ headerName }}</div>
            <div class="modalContentWrap_iconClose" @click="closeHandler">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 -4 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </div>
        </div>
        <div class="modalContentWrap_divider" />
        <div class="modalContentWrap_content">
            <slot></slot>
        </div>
        <div v-if="hasSlotBtns" class="modalContentWrap_divider" />
        <div v-if="hasSlotBtns" class="modalContentWrap_btnsWrap">
            <slot name="btns"></slot>
        </div>
    </div>
</template>
<script>
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
export default {
    props: {
        headerName: String,
        closeHandler: Function,
        showSpinner: Boolean
    },
    components: {
        MoonLoader
    },
    computed: {
        hasSlotBtns() {
            return !!this.$slots.btns;
        }
    }
};
</script>
<style lang="scss" scoped>
.modalContentWrap {
    min-width: 480px;
    max-width: 480px;
    color: #252525;
}

.modalContentWrap_divider {
    height: 1px;
    width: 100%;
    background-color: #ebeff6;
    border-radius: $border-sm;
}

.modalContentWrap_header {
    color: #252525;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 16px 16px 12px 16px;
}

.modalContentWrap_spiner {
    margin-right: 16px;
}

.modalContentWrap_headerName {
    font-size: 24px;
    line-height: 30px;
    color: #565656;
}

.modalContentWrap_iconClose {
    cursor: pointer;
    color: $black;
}

.modalContentWrap_content {
    padding: 16px;
}
.modalContentWrap_btnsWrap {
    padding: 12px 16px;
}

@media (max-width: 480px) {
    .modalContentWrap {
        // padding: 0px 16px 40px 16px;
        max-width: 95vw;
        // max-width: 480px;
        // color: #252525;
    }
}
</style>
