export default function() {
    if (this.spinner) return;


    if (this.amount.take === 0) {
        this.validations.take.isValid = false;
        this.validations.take.text = this.$t("exchanger.submitData.incorrectData");
    } else if (this.amount.give === 0) {
        this.validations.give.isValid = false;
        this.validations.give.text =  this.$t("exchanger.submitData.incorrectData");
    } else if (!this.isAuthenticated && !this.validateEmail(this.email)) {
        this.validations.email.isValid = false;
        this.validations.email.text = this.$t("exchanger.submitData.incorrectEmail");
    } else if (!this.agree) {
        console.log(this.agree, 'agree')
        this.errorAgree = true;
    } else if (this.validations.take.isValid && this.validations.give.isValid && this.validationFields()) {
        let isValidCaptcha = this.$refs.captchaOrder.validateCaptcha();

        if (isValidCaptcha) {
            this.spinner = true;
            this.createOrder();
        }
    }
}
