<template lang="">
    <div @mouseout="clickOutside">
        <div title="Скопировать поле" v-if="!copied" @click="onCopiedHandler" class="svg-icon-copy" id="copy">
            <svg class="copy-icon" height="100%" viewBox="0 -10 511.98685 511" width="100%" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill="#000"
                    d="m271 512h-191c-44.113281 0-80-35.886719-80-80v-271c0-44.113281 35.886719-80 80-80h191c44.113281 0 80 35.886719 80 80v271c0 44.113281-35.886719 80-80 80zm-191-391c-22.054688 0-40 17.945312-40 40v271c0 22.054688 17.945312 40 40 40h191c22.054688
                                 0 40-17.945312 40-40v-271c0-22.054688-17.945312-40-40-40zm351 261v-302c0-44.113281-35.886719-80-80-80h-222c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h222c22.054688 0 40 17.945312 40 40v302c0 11.046875 8.953125 20 20 20s20-8.953125 20-20zm0 0"
                />
            </svg>
        </div>
        <div class="copy-text" v-if="copied">
            {{ $t("popupOrder.info.copiedAction") }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        text: String
    },
    data() {
        return {
            copied: false
        };
    },
    methods: {
        clickOutside() {
            this.copied = false;
        },
        onCopiedHandler() {
            this.copied = true;
            this.copyTextToClipboard();
        },
        copyTextToClipboard() {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(this.text);
                return;
            }
            navigator.clipboard.writeText(this.text).catch(err => {
                console.error("Async: Could not copy text: ", err);
            });
        },
        fallbackCopyTextToClipboard(text) {
            let textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                let successful = document.execCommand("copy");
                let msg = successful ? "successful" : "unsuccessful";
                alert(msg);
            } catch (err) {
                console.error("Fallback: Oops, unable to copy", err);
            }
            document.body.removeChild(textArea);
        }
    }
};
</script>
<style lang="scss">
.svg-icon-copy {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.copy-icon > path {
    fill: #565656;
}
.copy-icon:hover > path {
    fill: #ff8c00;
}
.copy-text {
    color: #ff8c00;
    font-size: 14px;
}
</style>
